import styled from "@emotion/styled";
import { Checkbox } from "@kaltura/ds-react-components";
import {OptionProps} from "@mediaspace/features/channels-collections";

export interface OptionsProps {
    options: OptionProps[];
    onFilterUpdate: (value: string) => void;
}

const StyledFilterItemWrapper = styled("div")(({ theme }: { theme?: any }) => ({
    marginBottom: theme.spacing(2.5)
}));

const StyledFilterItemLabel = styled("span")(({ theme }: { theme?: any }) => ({
	marginLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    color: theme.kaltura.palette.tone2
}));

/**
 * Channelscollections single Filter
 */
const Options = ({
     options = [],
     onFilterUpdate
}: OptionsProps) => {

	return (
	    <>
            {options.map((option: OptionProps) => (
                <StyledFilterItemWrapper
                    key={option.value}
                    onClick={() => {onFilterUpdate(option.value)}}
                >
                    <Checkbox checked={option.checked} />
                    <StyledFilterItemLabel>{option.label.trim()}</StyledFilterItemLabel>
                </StyledFilterItemWrapper>
            ))}
        </>
	);
}

export default Options;
