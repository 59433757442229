import { MenuItem } from "@kaltura/ds-react-components";
import { styled } from "@mui/material";
import { ActionsMenuItemProps } from "../../ActionsMenu";
import {useButtonAnalytics} from "@mediaspace/hooks";

// Help typescript understand the actual props of the component
const StyledMenuItem = styled(MenuItem)((_: {component: "a", href: string, target?: string}) => ({}));

type AnalyticsProps = {
    pageName: string,
    buttonType: number
}

export interface LinkMenuItemProps extends ActionsMenuItemProps {
    label: string;
    href: string;
    target?: string;
    analytics?: AnalyticsProps;
}

/**
 * An item for ActionsMenu that contains a basic link.
 */
export const LinkMenuItem = ({label, href, target, autoFocus, analytics}: LinkMenuItemProps) => {

    const sendButtonAnalytics = useButtonAnalytics();

    const onMenuButtonClick = () => {
        if (analytics?.pageName && analytics?.buttonType) {
            sendButtonAnalytics(analytics.pageName, analytics.buttonType);
        }
    }

    return (
        <StyledMenuItem
            component={"a"}
            href={href}
            target={target || "_self"}
            autoFocus={autoFocus}
            onClick={onMenuButtonClick}
        >
            {label}
        </StyledMenuItem>
    )
}
