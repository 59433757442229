import { ComponentType, Suspense, useContext } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mediaspace/shared/styled";
import { Config, ConfigContext, ConfigProvider } from "@mediaspace/shared/data-kms-config";
import { SidebarLayout } from "@kaltura/ds-react-layouts";
import { EmbeddedCncWidgetContextProvider } from "@mediaspace/features/cnc";
import { KmsTypeComponent } from "@mediaspace/shared/types/KmsTypeComponent";
import { ToastsProvider } from "@mediaspace/shared/ui";
import { styled } from "@mui/material";

export interface DsLayoutProps<T> extends Omit<KmsTypeComponent<T>, "name"> {
    context: Config;
}


// make the main region 100% height
const StyledMainRegionWrapper = styled('div')({
   display: "flex",
   flexDirection: "column",
   minHeight: "inherit"
});

const StyledMainContentRegionWrapper = styled('div')({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
});

/**
 * Render a component in a full-react layout (the whole page is rendered here, including the header and the footer).
 * All layout-specific components are supposed to come from the context.
 *
 * Regions illustration: https://i.gyazo.com/b47c2860d2404292c1ac0585f78ba5e6.png
 */
export const DsLayout = <T,>({ context, ...mainComponent }: DsLayoutProps<T>) => {
    const { theming, dsLayout } = context;

    const renderRegion = (items: Omit<KmsTypeComponent, "name">[] = [], keyPrefix = "") => items
        .map((item, index) => ({ ...item, index }))
        // sort by order, preserve original order when the order is the same
        .sort((a, b) => ((a.order ?? 0) - (b.order ?? 0)) || (a.index - b.index))
        .map(({ component: Component, props, index }) => <Component key={keyPrefix + index} {...props} />);

    const configContext = useContext(ConfigContext);
    const mainAreaAttributes = !configContext.application?.isKAF ? {
        id: "contentWrap",
        role: "main",
        tabIndex: -1
    } : {}
    const renderMainRegion = () => {
        return (
        <StyledMainRegionWrapper className={"kms-ds-main-region"}>
            {renderRegion(dsLayout?.components.header, "header")}
            <StyledMainContentRegionWrapper {...mainAreaAttributes}>
                {renderRegion([mainComponent, ...(dsLayout?.components.main ?? [])], "main")}
            </StyledMainContentRegionWrapper>
            {renderRegion(dsLayout?.components.footer, "footer")}
        </StyledMainRegionWrapper>);
    }

    return (
        <Suspense fallback={<div/>}>
            <ConfigProvider context={context}>
                <ThemeProvider overrides={theming?.theme} mode={theming?.mode}>
                    <EmbeddedCncWidgetContextProvider>
                        <ToastsProvider toastDuration={4000}>
                            <SidebarLayout
                                mainRegion={renderMainRegion()}
                                floatedRegion={renderRegion(dsLayout?.components.floated)}
                                sidebarTopButtonsRegion={renderRegion(dsLayout?.components.sidebarTopButtons)}
                                sidebarBottomButtonsRegion={renderRegion(dsLayout?.components.sidebarBottomButtons)}
                                sidebarContentsRegion={renderRegion(dsLayout?.components.sidebarContents)}
                            />
                        </ToastsProvider>
                    </EmbeddedCncWidgetContextProvider>
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>
    );
};

/**
 * Render component in full-react layout.
 * This function could be used directly on the PHP side, e.g.
 * ```
 * <div id="some-id"></div>
 * <script>
 *     MEDIASPACE.DsLayout.renderPageComponent(
 *         'some-id',
 *         MEDIASPACE.AppName.ComponentReference,
 *         <?php echo $this->jsCodeEcho($this->props + ['context' => $configContext]); ?>
 *     );
 * </script>
 * ```
 */
export const renderPageComponent = <T,>(
    id: string,
    component: ComponentType<T>,
    props: T & { context: Config },
    order?: number
) => ReactDOM.render(
    <DsLayout context={props.context} component={component} props={props} order={order} />,
    document.getElementById(id)
);

/**
 * Return a render function for rendering component in full-react layout.
 * This helper could be used in a React app to prepare a page render function for the client side.
 * The render function takes element ID as the first argument, and component's props with a context object as the second argument,
 * so it would be used like the following on the PHP side:
 * ```
 * <div id="some-id"></div>
 * <script>
 *     MEDIASPACE.AppName.render('some-id', <?php echo $this->jsCodeEcho($this->props + ['context' => $configContext]); ?>);
 * </script>
 * ```
 */
export const getDsLayoutComponentRenderer = <T,>(component: ComponentType<T>, order?: number) =>
    (id: string, props: T & { context: Config }) => renderPageComponent(id, component, props, order);
