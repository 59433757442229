import styled from "@emotion/styled";
import { Typography } from "@kaltura/ds-react-components";
import { translate } from "@mediaspace/shared/utils";
import StyledIllustration from "@mediaspace/shared/ui/styled-illustration/StyledIllustration";

/* eslint-disable-next-line */
export interface NoSessionsProps {}

const StyledNoSessions = styled("div")(({ theme }: { theme?: any }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(25),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        marginBottom: theme.spacing(12.5),
    },
}));


const StyledTitle = styled(Typography)(({ theme }: { theme?: any }) => ({
    textAlign: "center",
}));

/**
 * Agenda No Sessions Found component.
 *
 * Themed illustration, receiving theme colors.
 */
export function NoSessions(props: NoSessionsProps) {
    return (
        <StyledNoSessions className="kms-ds-no-sessions">
            <StyledIllustration
                fileName={"Il_Search.svg"}
                xmlns="http://www.w3.org/2000/svg"
                width="300" height="300" fill="none" viewBox="0 0 300 300"
                className="kms-ds-no-sessions-image"
            >
                <g >
                    <g className="Tone1">
                        <path fill="#333" fillRule="evenodd" d="M86 68c-8.837 0-16 7.163-16 16s7.163 16 16 16h56c8.837 0 16-7.163 16-16s-7.163-16-16-16H86Zm-2 15.5a.5.5 0 0 0 0 1h60a.5.5 0 0 0 0-1H84ZM114.569 137.434a.499.499 0 1 0-.787-.617c-4.105 5.236-10.106 6.295-12.548 6.174l-.029-.001-.029.002c-4.797.321-9.713-1.349-13.326-4.532a48.823 48.823 0 0 1-1.485-1.384l-.195-.186-.287-.274c-.668-.635-1.36-1.272-2.087-1.831-1.45-1.115-3.089-1.957-5.02-1.808-1.553.12-2.966.839-4.19 1.619a111.301 111.301 0 0 0-4 2.658l-.22.152c-.779.542-1.359.945-2.15 1.423-2.139 1.287-4.341 2.447-6.713 2.663-2.606.242-5.286-.902-7.017-2.85a10.717 10.717 0 0 0 2.148-2.802c.734-1.396 1.47-3.117 1.528-4.571.03-.738-.113-1.466-.581-2.041-.473-.58-1.21-.924-2.2-1.024l-.016-.001-.014-.001c-.988-.041-1.804.276-2.426.852-.611.566-1.007 1.353-1.228 2.218-.44 1.722-.225 3.878.527 5.655.238.562.537 1.094.887 1.59-1.257 1.016-2.742 1.808-4.291 2.382a20.186 20.186 0 0 1-6.986 1.241.5.5 0 1 0 .001 1 21.18 21.18 0 0 0 7.333-1.303c1.617-.6 3.204-1.438 4.565-2.537 1.94 2.188 4.935 3.46 7.862 3.188 2.62-.239 4.994-1.513 7.137-2.803.82-.495 1.426-.916 2.202-1.456l.222-.154a110.42 110.42 0 0 1 3.967-2.636c1.184-.753 2.427-1.364 3.73-1.465 1.573-.121 2.967.553 4.334 1.604.682.525 1.343 1.132 2.007 1.763l.453.433.011.01.005.004c.505.484 1.015.972 1.526 1.423 3.806 3.352 8.97 5.111 14.025 4.781 2.693.122 9.016-1.024 13.355-6.558Zm-58.82-2.059a9.705 9.705 0 0 1-1.883 2.476 7.518 7.518 0 0 1-.72-1.314c-.679-1.602-.86-3.533-.48-5.017.189-.739.508-1.334.938-1.732.417-.386.966-.614 1.69-.587.806.084 1.257.347 1.511.659.26.319.382.774.358 1.369-.048 1.213-.687 2.762-1.414 4.146Z" clipRule="evenodd"/>
                        <path fill="#333" fillRule="evenodd" d="M230 116c0-8.837 7.164-16 16-16 8.837 0 16 7.163 16 16h2c11.046 0 20 8.954 20 20s-8.954 20-20 20h-40.81a30.228 30.228 0 0 0-.382-1H264c10.494 0 19-8.507 19-19s-8.506-19-19-19h-2.031c-.516 8.371-7.468 15-15.969 15-8.5 0-15.453-6.629-15.969-15H36.001c-10.494 0-19 8.507-19 19s8.506 19 19 19h132.298c-.133.331-.261.665-.382 1H36c-11.046 0-20-8.954-20-20s8.954-20 20-20h194Zm9.636-7.071a.5.5 0 0 0-.707.707l6.364 6.364-6.364 6.364a.5.5 0 0 0 .707.707l6.364-6.364 6.364 6.364a.5.5 0 0 0 .707-.707L246.707 116l6.364-6.364a.5.5 0 0 0-.707-.707L246 115.293l-6.364-6.364Z" clipRule="evenodd"/>
                        <path fill="#333" d="M212.789 189.802c.227.43.522.833.883 1.194l21.213 21.213a5 5 0 0 0 7.071 0 5 5 0 0 0 0-7.071l-21.213-21.213a4.963 4.963 0 0 0-1.233-.904 29.573 29.573 0 0 1-3.18 3.756 29.554 29.554 0 0 1-3.541 3.025ZM218.55 182.643a28.6 28.6 0 0 1-6.148 6.202 5 5 0 0 1 6.148-6.202ZM222.124 156h-53.141c.126-.335.259-.669.398-1h52.345c.139.331.272.665.398 1ZM36 177a5 5 0 0 1 5-5h52a5 5 0 1 1 0 10H41a5 5 0 0 1-5-5ZM41 202a5 5 0 1 0 0 10h20a5 5 0 0 0 0-10H41ZM137 202a5 5 0 1 0 0 10h18a5 5 0 1 0 0-10h-18ZM41 232a5 5 0 1 0 0 10h52a5 5 0 0 0 0-10H41ZM153 242a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM173 242a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM193 242a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"/>
                    </g>
                    <g className="Primary">
                        <path fill="#006EFA" d="M111.984 84.5a8 8 0 0 1-15.969 0h15.969ZM111.984 83.5H96.015a8 8 0 0 1 15.969 0ZM108 177a5 5 0 0 1 5-5h20a5 5 0 1 1 0 10h-20a5 5 0 0 1-5-5ZM76 207a5 5 0 0 1 5-5h36a5 5 0 1 1 0 10H81a5 5 0 0 1-5-5ZM108 237a5 5 0 0 1 5-5h20a5 5 0 1 1 0 10h-20a5 5 0 0 1-5-5ZM215.623 186.07c11.084-11.085 11.084-29.055 0-40.14-11.084-11.084-29.055-11.084-40.139 0-11.084 11.085-11.084 29.055 0 40.14 11.084 11.084 29.055 11.084 40.139 0Z"/>
                        <path fill="#006EFA" fillRule="evenodd" d="M222.61 139.13c14.84 14.84 14.84 38.9 0 53.74-14.84 14.84-38.9 14.84-53.74 0-14.84-14.84-14.84-38.9 0-53.74 14.84-14.84 38.9-14.84 53.74 0Zm-6.28 47.647c11.475-11.475 11.475-30.079 0-41.554-11.475-11.474-30.079-11.474-41.553 0-11.475 11.475-11.475 30.079 0 41.554 11.474 11.474 30.078 11.474 41.553 0Z" clipRule="evenodd"/>
                    </g>
                </g>
            </StyledIllustration>
            <StyledTitle variant={"h2"}>
                {translate("No Matching results found")}
            </StyledTitle>
        </StyledNoSessions>
    );
}

export default NoSessions;
