import styled from "@emotion/styled";
import { RadioFormControlLabel } from "@kaltura/ds-react-components";
import { formControlLabelClasses } from "@mui/material";
import { KmsTypeFilterItemRadio } from "@mediaspace/shared/types";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import { ChangeEvent } from "react";

export interface RadioFilterItemProps extends KmsTypeFilterItemRadio {
    onChange: (param: string, value: string, checked: boolean, equalsAll: boolean) => void;
}

const StyledRadioFilterItem = styled("div")(({ theme }) => ({
    marginTop: 0,
    marginBottom: theme.spacing(2),
    [`& .${formControlLabelClasses.label} p`]: { fontSize: 14 },
}));

/**
 * radio filter item - a single (controlled)radio in a filter
 */
export function RadioFilterItem(props: RadioFilterItemProps) {
    const sendButtonAnalytics = useButtonAnalytics();
    const { label, param, value, checked = false, onChange, disabled = false } = props;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const eventName = `Filters - ${param} - ${value}`
        sendButtonAnalytics(eventName, ButtonClickAnalyticsType.FILTER);
        onChange(param, value, event.target.checked, false);
    };

    return (
        <StyledRadioFilterItem>
            <RadioFormControlLabel
                label={label}
                value={value}
                disabled={disabled}
                checked={checked}
                onChange={handleChange}
            />
        </StyledRadioFilterItem>
    );
}

export default RadioFilterItem;
