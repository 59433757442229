import ReactDOM from "react-dom";
import React, { Suspense } from "react";
import { ThemeProvider } from "@mediaspace/shared/styled";
import { Config, ConfigProvider } from "@mediaspace/shared/data-kms-config";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { StatusScreenProps } from "@mediaspace-react-components/features/email-login";

const StatusScreen = React.lazy(
    () => import("@mediaspace-react-components/features/email-login")
);

const render = (
    containerId: string,
    props: StatusScreenProps & { context: Config }
) => {

    const { theming } = props.context;
    ReactDOM.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming.theme} mode={theming.mode}>
                    <StatusScreen {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(containerId)
    );
};

export { render };
