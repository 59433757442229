import { FilterProps, KmsTypeFilterBase, KmsTypeFilterItemBase } from "@mediaspace/shared/types";
import { CheckboxFilter } from "./checkbox-filter/CheckboxFilter";
import { RadioFilter } from "./radio-filter/RadioFilter";
import { FreeTextFilter } from "./free-text-filter/FreeTextFilter";
import DateFilter from "./date-filter/DateFilter";


/**
 * filter - a filter factory
 */
export function Filter(props: FilterProps<KmsTypeFilterBase<KmsTypeFilterItemBase>>) {

    // `freeText` is a prop of KmsTypeFilterFreeText only, it is not part of the base,
    //  but it's ok because none of the other types will have it
    // it must be before singleSelection and multiSelection
    if ("freeText" in props) {
        return <FreeTextFilter {...props} />;
    }
    if ("dateFilter" in props) {
        return <DateFilter {...props} />;
    }

    // these can be on any filter, so must be last
    if ("singleSelection" in props && props.singleSelection) {
        return <RadioFilter {...props} />;
    }

    if ("multiSelection" in props && props.multiSelection) {
        return <CheckboxFilter {...props} />;
    }

    return null;
}

export default Filter;
