import styled from "@emotion/styled";
import { KmsTypeFilterItemFreeText } from "@mediaspace/shared/types";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import { BaseInput, Typography } from "@kaltura/ds-react-components";
import { Theme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";

export interface FreeTextFilterItemProps extends KmsTypeFilterItemFreeText {
    onChange: (param: string, value: string, checked: boolean, equalsAll: boolean) => void;
}

const StyledFreeTextFilterItem = styled(FormControl)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const StyledFieldLabel = styled(Typography)(
    ({ component, theme }: { component: "div"; theme?: Theme }) => ({
        color: theme.kaltura.palette.tone3,
        marginBottom: theme.spacing(1),
    })
);

/**
 * free text filter item - a single (controlled) free text filter item
 */
export function FreeTextFilterItem({
    label,
    param,
    value = "",
    onChange,
    disabled = false,
}: FreeTextFilterItemProps) {
    const sendButtonAnalytics = useButtonAnalytics();

    const [innerValue, setInnerValue] = useState(value);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInnerValue(event.target.value);
    };

    /**
     * submit search on enter key
     */
    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== "Enter") {
            return;
        }
        const value = (event.target as HTMLInputElement).value;
        // analytics
        const eventName = `Filters - ${param}`;
        sendButtonAnalytics(eventName, ButtonClickAnalyticsType.FILTER);
        // submit change
        onChange(param, value, true, false);
    };

    return (
        <StyledFreeTextFilterItem>
            <StyledFieldLabel component={"div"} variant={"formLabel"}>
                {label}
            </StyledFieldLabel>
            <BaseInput
                fullWidth={true}
                value={innerValue}
                disabled={disabled}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
        </StyledFreeTextFilterItem>
    );
}

export default FreeTextFilterItem;
