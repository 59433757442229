import { FilterItem } from "../filter-items/FilterItem";
import {
    FilterValues,
    KmsTypeFilterRadio,
    KmsTypeFilterItemGroup, FilterProps, KmsTypeFilterItemBase
} from "@mediaspace/shared/types";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";

export type RadioFilterProps = FilterProps<KmsTypeFilterRadio>;

/**
 * radio filter - holds several radio items, manages their value and presentation
 *
 * we never allow radio items to have specific params.
 */
export function RadioFilter(props: RadioFilterProps) {
    const { param, values = {}, defaultValue, items, onChange } = props;

    // filter group items
    const groupItems = items.filter((item) => "groupItem" in item);
    // filter group items' params (params of the group items, no empties and flattened)
    const subItemsParams = groupItems
        .map((item: KmsTypeFilterItemGroup) =>
            item.items.map((item) => item.param)
        )
        .flat()
        .filter((param) => param);

    /**
     * handle filter item change
     */
    const handleChange = (param: string, value: string, checked: boolean) => {
        if (checked) {

            // replace value with current selection values
            const filterValues: FilterValues = { [param]: value };

            if (!subItemsParams.includes(param)) {
                // if the item that triggered change is not a sub-item in a group
                if (value) {
                    // reset this filters group items values
                    subItemsParams.forEach((param) => {
                        filterValues[param] = "";
                    });
                }
            }

            onChange(filterValues);
        } else {
            // remove value
            onChange({ [param]: "" });
        }
    };

    const isChecked = (item: KmsTypeFilterItemBase) => {
        // this filter value: radio takes a single value, so it's a string
        const currentValue = values[param]?.toString();
        if (typeof values[param] === "string") {
            // there is a real value, possibly empty string
            if (typeof item.value === "undefined") {
                // group items don't have a value, if we got an empty string - that's it
                return "" === currentValue;
            }
            return item.value === currentValue;
        }
        else {
            return item.value === defaultValue;
        }
    }

    return (
        <FormControl className="kms-ds-radio-filter">
            <RadioGroup
                aria-labelledby={`${param}-header`}
                defaultValue={defaultValue}
                name={`radio-buttons-group-${param}`}
            >
                {items.map((item, index) => (
                    <FilterItem
                        key={item.value + index}
                        {...item}
                        param={param}
                        disabled={item.disabled}
                        checked={isChecked(item)}
                        values={values}
                        onChange={handleChange}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
}

export default RadioFilter;
