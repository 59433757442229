/**
 * add scheduled media to different calendars.
 * see usage example in EventList.tsx
 */
import { WebcastEntry } from "@mediaspace/shared/types";
import { baseUrl } from "./kms";
import { Config } from "@mediaspace/shared/data-kms-config";

export default class AddToCalendarHelper {
    static getAddToCalendarAction = (config: Config) => {
        const url = config?.calendar?.getAppointmentUrl;
        return (entry: WebcastEntry, calendarType: "google" | "outlook") => {
            if (!entry) {
                return;
            }

            const params = "/entryid/" + entry.id + "/cal/" + calendarType;
            window.open(baseUrl + url + params);
        };
    };
}
