import {ReactNode, useState} from "react";
import {Tooltip, tooltipClasses, TooltipProps} from "@kaltura/ds-react-components";
import TruncateMarkup from "react-truncate-markup";
import styled from "@emotion/styled";
import {Theme} from "@mui/material";

export interface TruncateWithTooltipProps {
    className?: string;
    tooltipPlacement?: TooltipProps["placement"];
    tokenize?: string;
    children: ReactNode;
    lines?: number;
}

const StyledTooltip = styled(Tooltip)(({theme}: { theme: Theme}) => ({
    [`&.${tooltipClasses.tooltip}`]: {
        fontWeight: theme.kaltura.typography.fontWeightBold
    },
    [`& .${tooltipClasses.arrow}::before`]: {
        borderTopLeftRadius: theme.kaltura.shape.roundness1
    }
}));

const StyledChildrenWrapper = styled("span")(() => ({
    display: "block"
}));

/**
 * This component shows truncated or not truncated text.
 * If the text is truncated the full text is shown inside a tooltip.
 */
export const TruncateWithTooltip = ({className, tooltipPlacement = "bottom", tokenize, children, lines = 1}: TruncateWithTooltipProps) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const handleTruncate = wasTruncated => {
        setShowTooltip(wasTruncated);
    }

    return (
        <StyledTooltip className={className} title={showTooltip ? children : ""} placement={tooltipPlacement}>
            <span>
                <TruncateMarkup lines={lines} tokenize={tokenize} onTruncate={handleTruncate}>
                    <StyledChildrenWrapper className={"chromatic-ignore"}>
                        {children}
                    </StyledChildrenWrapper>
                </TruncateMarkup>
            </span>
        </StyledTooltip>
    )
};
