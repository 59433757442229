import StyledIllustration from "@mediaspace/shared/ui/styled-illustration/StyledIllustration";

export const ErrorIllustration = () => {
    return (
        <StyledIllustration fileName={"Error.svg"} xmlns="http://www.w3.org/2000/svg" width="300" height="300" fill="none" viewBox="0 0 300 300">
            <g >
                <g className="Tone1">
                    <path fill="#333" fillRule="evenodd" d="M198.036 90.214a2 2 0 0 0-3.307-.7l-26.64 27.311a1.994 1.994 0 0 0-.275 2.443l-30.686-15.636a8 8 0 0 0-10.76 3.496l-3.632 7.128a8 8 0 0 0 3.496 10.76L143.864 134H126a2 2 0 0 0-2 2v17a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2v-17c0-.393-.114-.76-.31-1.069l30.438 15.509a8 8 0 0 0 10.76-3.497l3.632-7.128a8 8 0 0 0-3.496-10.76l-17.08-8.702 39.05 8.117c1.54.32 2.83-1.181 2.281-2.655l-13.239-35.6Zm-11.466 39.732-49.896-25.423a7 7 0 0 0-9.415 3.059l-3.632 7.128a6.999 6.999 0 0 0 3.059 9.415l49.896 25.424a7 7 0 0 0 9.415-3.06l3.632-7.128a7.001 7.001 0 0 0-3.059-9.415ZM54 200a8 8 0 0 1 8-8h38a8 8 0 0 1 8 8v32a8.004 8.004 0 0 1-5.21 7.5h26.42A8.002 8.002 0 0 1 124 232v-17a8 8 0 0 1 8-8h47c.549 0 1.085.055 1.604.161l-18.277-13.279a8 8 0 0 1-1.77-11.175l16.374-22.536a8 8 0 0 1 11.174-1.77l22.653 16.458a8 8 0 0 1 1.77 11.174l-16.374 22.537a8.002 8.002 0 0 1-10.28 2.334A7.967 7.967 0 0 1 187 215v17a8.002 8.002 0 0 1-5.21 7.5H276a.5.5 0 0 1 0 1H24a.5.5 0 0 1 0-1h35.21A8.003 8.003 0 0 1 54 232v-32Zm17.5 6a.5.5 0 0 1 .5-.5h21a.5.5 0 0 1 0 1H72a.5.5 0 0 1-.5-.5Zm-9 10a.5.5 0 0 1 .5-.5h24a.5.5 0 0 1 0 1H63a.5.5 0 0 1-.5-.5Zm12.5 9.5a.5.5 0 0 0 0 1h18a.5.5 0 0 0 0-1H75Zm125.798-43.013a.5.5 0 0 0-.137-.991l-13.18 1.825-2.337-13.098a.5.5 0 1 0-.984.175l2.33 13.06-13.14 1.82a.499.499 0 1 0 .137.99l13.179-1.825 2.337 13.099a.5.5 0 0 0 .985-.176l-2.331-13.06 13.141-1.819ZM179 208h-47a7 7 0 0 0-7 7v17a7 7 0 0 0 7 7h47a7 7 0 0 0 7-7v-17a7 7 0 0 0-7-7Z" clipRule="evenodd"/>
                </g>
                <g className="Primary">
                    <path fill="#006EFA" d="M191.043 158.076c-5.27-9.707-1.674-21.849 8.033-27.119 9.707-5.271 21.849-1.674 27.12 8.033 5.27 9.707 1.674 21.849-8.033 27.12-9.707 5.27-21.849 1.674-27.12-8.034ZM244.79 239.5A8.002 8.002 0 0 0 250 232v-37a8 8 0 0 0-8-8h-29c-.423 0-.839.033-1.245.096L205 196.394V232a8.002 8.002 0 0 0 5.21 7.5h34.58Z"/>
                    <path fill="#006EFA" fillRule="evenodd" d="M148.256 174.963c4.002 14.937-4.862 30.29-19.799 34.293-14.937 4.002-30.29-4.862-34.293-19.799-4.002-14.937 4.862-30.291 19.799-34.293 14.937-4.003 30.29 4.862 34.293 19.799Zm-15.442 5.93c1.089-1.088.591-2.947-.896-3.346l-17.65-4.729c-1.487-.398-2.848.963-2.449 2.45l4.729 17.649c.398 1.487 2.257 1.985 3.346.897l12.92-12.921Z" clipRule="evenodd"/>
                </g>
            </g>
        </StyledIllustration>
    );
}
