import styled from "@emotion/styled";
import { Carousel } from "../carousel/Carousel";
import { CarouselItem } from "../carousel/CarouselItem";
import { useTitle } from "./../list-title/useTitle";
import { ListTitle, ListTitleProps } from "./../list-title/ListTitle";
import { EventPlatformEntry } from "@mediaspace/shared/types";
import { systemWidth } from "@mediaspace/shared/styled";
import { useTimeShiftedArray } from "@mediaspace/hooks";

export interface SinglePlaylistProps extends ListTitleProps {
    entries: EventPlatformEntry[];
    playlistId?: string;
    categoryId?: number;
    noLink?: boolean;
    itemsPerPage?: 2 | 3 | 4 | 5;
    timeShift?: number; // time shift in minutes
}

const StyledSystemWidthDiv = styled("div")(({ theme }: { theme?: any }) => ({
    "&": systemWidth({ theme }),
}));

/**
 * SinglePlaylist component displays vod / live entries in a carousel
 */
export function SinglePlaylist(props: SinglePlaylistProps) {
    const {
        entries,
        playlistId = "",
        categoryId,
        noLink = false,
        itemsPerPage = 4,
        timeShift = 0,
    } = props;

    const titleProps = useTitle(props);

    const carouselEntries = useTimeShiftedArray(
        entries,
        // 1 minute = 60,000 milliseconds
        timeShift * 60000
    );

    if (!entries.length) {
        return null;
    }

    return (
        <div className="single-playlist" id="kms-ds-homepage-component-single-playlist">
            {titleProps.show && (
                <StyledSystemWidthDiv className="kms-ds-single-playlist-title">
                    <ListTitle {...titleProps} analyticsActionText={"Playlist - See all"} />
                </StyledSystemWidthDiv>
            )}
            <Carousel
                items={carouselEntries}
                itemComponent={CarouselItem}
                itemProps={{
                    playlistId,
                    categoryId,
                    noLink,
                }}
                currentCardsNumberInSlides={itemsPerPage}
                minPossibleCardsNumberInSlides={itemsPerPage}
                container={"Playlist"}
            />
        </div>
    );
}

export default SinglePlaylist;
