import React from "react";
import styled from "@emotion/styled";
import { Typography } from "@kaltura/ds-react-components";
import { Box } from "@mediaspace/shared/styled";

export const FormFieldContainer = styled(Box)(({theme} : { theme?: any }) => ({
    marginBottom: theme.spacing(3)
}));

export const ValidationErrorText = styled(Typography)(({ theme }: { theme?: any }) => ({
    color: theme.kaltura.palette.danger.main,
    display: "block",
    marginTop: theme.spacing(1),
}));
