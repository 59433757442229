import styled from "@emotion/styled";
import Filter from "../filters/Filter";
import {FilterItem} from "@mediaspace/features/channels-collections";

export interface FiltersProps {
    filters: FilterItem[];
    onFilterUpdate: (value: string) => void;
    isModal: boolean;
}

const StyledFiltersSlider = styled("div", {
    shouldForwardProp(propName: PropertyKey): boolean {
        return propName !== "isModal";
    }
})(({ theme, isModal }: { theme?: any; isModal: boolean }) => ({
    display: isModal ? "block" : "flex",
    paddingTop: theme.spacing(3)
}));

/**
 * copmonent for rendering filters groups slider
 */
const Filters = ({
    filters,
    onFilterUpdate,
    isModal = false
}: FiltersProps) => {

    return (
        <StyledFiltersSlider isModal={isModal}>
            {
                filters.map((filterData, index) => {
                    return (
                        <Filter
                            label={filterData.title}
                            options={filterData.items}
                            onFilterUpdate={onFilterUpdate}
                            isModal={isModal}
                        />
                    )
                })
            }
        </StyledFiltersSlider>
    );
};

export default Filters;
