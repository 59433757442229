import { forwardRef, MouseEvent, KeyboardEvent, ReactNode, useRef, useState } from "react";
import styled from "@emotion/styled";
import {
    Button,
    buttonClasses,
    IconButton,
    Menu,
    MenuItem,
    NestedMenuItem,
} from "@kaltura/ds-react-components";
import { Theme } from "@mui/material";
import { ChevronDown16Icon, ChevronRight16Icon } from "@kaltura/ds-react-icons";
import { SidebarMenuItem } from "@mediaspace/shared/types/SidebarMenuItem";
import { translate } from "@mediaspace/shared/utils";
import { Box } from "@mediaspace/shared/styled";
import {useButtonAnalytics} from "@mediaspace/hooks";
import {ButtonClickAnalyticsType} from "@mediaspace/shared/types/ButtonClickAnalyticsType";

export interface HorizontalMenuItemProps extends SidebarMenuItem {
    id: string;
    icon?: ReactNode;
    buttonVariant?: "pill" | "borderless";
    buttonColor?: "translucent" | "primary";
    openOnHover?: boolean;
    isTab?: boolean;
    /**
     * if true, this item is not visible on screen, it is used only for measuring width
     */
    hidden?: boolean;
    buttonAnalyticsData?: Record<string, string>;
}

// region Styled components
const StyledMenuIconButton = styled(IconButton)(
    ({ theme }: { theme?: any }) => ({
        marginRight: theme.spacing(1),
    })
);

const StyledMenuButton = styled(Button, {
    shouldForwardProp: (prop) => !(["active", "isTab", "anchorEl"].includes(prop.toString())),
})(
    ({
        theme,
        component,
        target,
        active,
        variant = "borderless",
        isTab,
        anchorEl
    }: {
        theme?: any;
        component?: "a";
        target?: string;
        active?: boolean;
        variant?: "pill" | "borderless";
        isTab?: boolean;
        anchorEl?: HTMLElement | null;
    }) => ({
        marginRight: theme.spacing(isTab ? 3 : 1),
        maxWidth: 152,
        minWidth: isTab ? 0 : "auto",
        paddingLeft: isTab ? 0 : "auto",
        paddingRight: isTab ? 0 : "auto",
        // default Modal zIndex is 1300, we want this under. same for Toast.
        zIndex: 1250,
        [`& .${buttonClasses.endIcon}`]: {
            marginLeft: 0,
        },
        ...(variant === "borderless" && {
            [`&:hover, &:active, &:focus`]: {
                backgroundColor: "transparent",
                // this line is for kms legacy integrations
                color: theme.kaltura.palette.translucent.main,
                "& .kms-ds-horizontal-menuitem-active": {
                    // display: "inline",
                    display: !anchorEl ? "inline" : "none",
                    padding: "0",
                },
            },
            ...(active && {
                "& .kms-ds-horizontal-menuitem-active": {
                    display: "inline",
                    padding: "0",
                },
            }),
        }),
        ...(variant === "pill" && {
            [`&:hover, &:active, &:focus`]: {
                // this line is for kms legacy integrations
                color: theme.kaltura.palette.translucent.main,
            },
        }),
        // reset bootstrap styles for non-ds pages:
        "&:focus" : {
            outline: "none",
            textDecoration: "none"
        }
    })
);

const StyledMenuButtonLabel = styled.span(({ theme }: { theme?: any }) => ({
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline",
    whiteSpace: "nowrap",
}));

const StyledMenuButtonActiveIndication = styled.span(
    ({ theme }: { theme?: any }) => ({
        display: "none",
        position: "absolute",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        top: 26,
        left: 0,
        minHeight: 3,
        width: "100%",
        boxSizing: "border-box",
    })
);

const StyledMenuButtonActiveIndicationInner = styled.span(
    ({ theme }: { theme?: any }) => ({
        display: "inline-block",
        minHeight: 3,
        width: "100%",
        backgroundColor: theme.palette.common.white,
    })
);

const StyledMenuItem = styled(MenuItem)(
    ({
        theme,
        component,
        target,
        href,
    }: {
        theme?: any;
        component: "a";
        target: string;
        href: string;
    }) => ({
        color: theme.kaltura.palette.tone1,
        textDecoration: "none",
        // override v2ui.css, for legacy kms pages
        [`&:hover, &:active, &:focus`]: {
            color: theme.kaltura.palette.tone1,
            textDecoration: "none",
        },
    })
);

const StyledNestedMenuItem = styled(NestedMenuItem)(
    ({
         theme,
         component,
         target,
     }: {
        theme?: Theme;
        component?: "a";
        target?: string;
    }) => ({})
);

const StyledMenu = styled(Menu)(
    ({theme}: {theme?: any}) => ({
        // this should be under the dropdown that opens (see StyledMenuButton above)
        zIndex: 1200,
    })
);
// endregion

/**
 * Header Menu Horizontal Menu Item (top level item)
 */
export const HorizontalMenuItem = forwardRef<HTMLDivElement, HorizontalMenuItemProps>((props, ref) => {
    const {
        id = "",
        label,
        icon,
        uri,
        target = "_self",
        active = false,
        pages = [],
        buttonVariant = "borderless",
        buttonColor = "translucent",
        openOnHover = false,
        isTab,
        hidden = false,
        buttonAnalyticsData
    } = props;

    const buttonRef = useRef<any>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        sendAnalytics(buttonAnalyticsData?.analyticsValue);
    };

    const handleKeyDown = (event?: KeyboardEvent<HTMLButtonElement>) => {
        if (event?.key === "ArrowDown" || event?.key === " ") {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // menu items that are also links (category with sub-categories) need
    // to open on hover, so the click will activate the link
    const handleMouseEnter = (event: MouseEvent<HTMLButtonElement>) => {
        openOnHover && setAnchorEl(event.currentTarget);
    };

    // close behaviour for the activated on hover menu
    const handleMouseLeave = (event: MouseEvent<HTMLButtonElement>) => {
        openOnHover && setAnchorEl(null);
    };

    // keep the menu open when hovering on the menu, not the menu button
    const handleMouseMenuEnter = (event: MouseEvent<HTMLDivElement>) => {
        openOnHover && buttonRef.current && setAnchorEl(buttonRef.current);
    };

    // close behaviour for the activated on hover menu
    const handleMouseMenuLeave = (event: MouseEvent<HTMLDivElement>) => {
        openOnHover && buttonRef.current && setAnchorEl(null);
    };

    // to skip hidden menu items (used for measuring only) when navigating with keyboard
    const hiddenProps = hidden ? {tabIndex: -1} : {};

    // analytics
    const sendButtonAnalytics = useButtonAnalytics();
    const sendAnalytics = (actionName?: string) => {
        if (!actionName) {
            return;
        }
        sendButtonAnalytics(actionName, ButtonClickAnalyticsType.NAVIGATE);
    }

    /**
     * render menu items recursively with sub menu
     */
    const renderItem = (item: SidebarMenuItem, index: number) => {
        const {
            label,
            uri = "",
            target = "_self",
            pages = [],
        } = item;

        if (pages.length === 0) {
            return (
                <StyledMenuItem
                    className={"kms-ds-submenu-menuitem"}
                    key={index}
                    href={uri}
                    target={target === null ? "_self" : target}
                    component="a"
                    onClick={() => sendAnalytics(item?.buttonAnalyticsData?.analyticsValue)}
                    {...hiddenProps}
                >
                    {translate(label)}
                </StyledMenuItem>
            );
        } else {
            return (
                <StyledNestedMenuItem
                    className={"kms-ds-submenu-menuitem kms-ds-submenu-menuitem-nested"}
                    key={index}
                    component={uri ? "a" : undefined}
                    href={uri ? uri : undefined}
                    target={!uri ? undefined : target === null ? "_self" : target}
                    label={translate(label)}
                    icon={<ChevronRight16Icon />}
                    onClick={uri ? () => sendAnalytics(item?.buttonAnalyticsData?.analyticsValue) : undefined }
                    {...hiddenProps}
                    submenuClasses={{
                        root: `kms-ds-submenu`,
                        list: `kms-ds-submenu-list`,
                        paper: `kms-ds-submenu-paper`,
                    }}
                >
                    {pages.map((item: SidebarMenuItem, index: number) => {
                        return renderItem(item, index);
                    })}
                </StyledNestedMenuItem>
            );
        }
    };

    return (
        <Box
            ref={ref}
            display={"inline-block"}
            className="kms-ds-horizontal-menuitem"
        >
            {/* regular menu item */}
            {pages.length === 0 && (
                <StyledMenuButton
                    id={`HorizontalMenuToggleBtn${id}`}
                    component="a"
                    role="link"
                    variant={buttonVariant}
                    color={buttonColor}
                    active={active}
                    href={uri}
                    target={target === null ? undefined : target}
                    isTab={isTab}
                    onClick={() => sendAnalytics(buttonAnalyticsData?.analyticsValue)}
                    {...hiddenProps}
                >
                    <StyledMenuButtonLabel>
                        {translate(label)}
                        <StyledMenuButtonActiveIndication className="kms-ds-horizontal-menuitem-active">
                            <StyledMenuButtonActiveIndicationInner />
                        </StyledMenuButtonActiveIndication>
                    </StyledMenuButtonLabel>
                </StyledMenuButton>
            )}
            {pages.length > 0 && (
                <>
                    {/* `more`(...) menu item */}
                    {icon && (
                        <StyledMenuIconButton
                            className={`kms-ds-horizontal-menuitem-iconbtn kms-ds-horizontal-menuitem-btn-${id}`}
                            id={`HorizontalMenuToggleBtn${id}`}
                            aria-haspopup={true}
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleButtonClick}
                            onKeyDown={handleKeyDown}
                            aria-controls={`HorizontalMenu${id}`}
                            variant={"pill"}
                            color="translucent"
                            aria-label={translate(label)}
                            {...hiddenProps}
                        >
                            {icon}
                        </StyledMenuIconButton>
                    )}

                    {/* menu item with sub-menu */}
                    {!icon && (
                        <StyledMenuButton
                            className={`kms-ds-horizontal-menuitem-btn kms-ds-horizontal-menuitem-btn-${id}`}
                            id={`HorizontalMenuToggleBtn${id}`}
                            aria-haspopup={true}
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleButtonClick}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onKeyDown={handleKeyDown}
                            href={uri}
                            target={target === null ? undefined : target}
                            component={uri ? "a" : undefined}
                            ref={buttonRef}
                            aria-controls={`HorizontalMenu${id}`}
                            variant={buttonVariant}
                            color={buttonColor}
                            endIcon={<ChevronDown16Icon />}
                            active={active}
                            isTab={isTab}
                            anchorEl={anchorEl}
                            {...hiddenProps}
                        >
                            <StyledMenuButtonLabel>
                                {translate(label)}
                                <StyledMenuButtonActiveIndication className="kms-ds-horizontal-menuitem-active">
                                    <StyledMenuButtonActiveIndicationInner />
                                </StyledMenuButtonActiveIndication>
                            </StyledMenuButtonLabel>
                        </StyledMenuButton>
                    )}
                    <StyledMenu
                        classes={{
                            root: `kms-ds-submenu kms-ds-submenu-${id}`,
                            list: "kms-ds-submenu-list",
                            paper: "kms-ds-submenu-paper",
                        }}
                        id={`HorizontalMenu${id}`}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                        aria-labelledby={`HorizontalMenuToggleBtn${id}`}
                        PaperProps={{
                            onMouseEnter: handleMouseMenuEnter,
                            onMouseLeave: handleMouseMenuLeave,
                        }}
                        disablePortal={openOnHover && !icon}
                    >
                        {pages.map((item: SidebarMenuItem, index: number) =>
                            renderItem(item, index)
                        )}
                    </StyledMenu>
                </>
            )}
        </Box>
    );
});

export default HorizontalMenuItem;
