import {
    CncProps,
    FloatedCnc,
    EmbeddedCncContainer,
    EmbeddedCncSidebarTopButtons,
    EmbeddedCncSidebarBottomButtons,
    EmbeddedCncSidebarContents,
    EmbeddedCncReactionsDesktop,
    EmbeddedCncReactionsMobile,
} from "@mediaspace/features/cnc";
import ReactDOM from "react-dom";
import React from "react";

export const Floated = {
    render: (props: CncProps) => {
        const root = document.createElement("div");
        document.body.append(root);

        ReactDOM.render(<FloatedCnc {...props} />, root);
    },
};

export const Embedded = {
    Container: EmbeddedCncContainer,
    Sidebar: {
        Contents: EmbeddedCncSidebarContents,
        Buttons: {
            Top: EmbeddedCncSidebarTopButtons,
            Bottom: EmbeddedCncSidebarBottomButtons,
        },
    },
    Reactions: {
        Desktop: EmbeddedCncReactionsDesktop,
        Mobile: EmbeddedCncReactionsMobile,
    },
};
