import {
    KmsTypeFilterItemCheckbox,
    KmsTypeFilterItemRadio,
    KmsTypeFilterItemFreeText,
    KmsTypeFilterItemDropdown,
    KmsTypeFilterItemGroup,
    FilterValues, KmsTypeFilterItemDate, KmsTypeFilterItemRange
} from "@mediaspace/shared/types";
import { CheckboxFilterItem } from "./CheckboxFilterItem";
import { RadioFilterItem } from "./RadioFilterItem";
import { FreeTextFilterItem } from "./FreeTextFilterItem";
import { GroupFilterItem } from "./GroupFilterItem";
import DateFilterItem from "./DateFilterItem";
import RangeFilterItem from "./RangeFilterItem";

export type FilterItemProps = (
    KmsTypeFilterItemCheckbox
    | KmsTypeFilterItemRadio
    | KmsTypeFilterItemFreeText
    | KmsTypeFilterItemDate
    | KmsTypeFilterItemGroup
    | KmsTypeFilterItemDropdown
    | KmsTypeFilterItemRange
) & {
    onChange: (
        param: string,
        value: string,
        checked: boolean,
        equalsAll: boolean
    ) => void;
    values?: FilterValues
};

/**
 * filter item - a filter item factory
 */
export function FilterItem(props: FilterItemProps) {
    if ("groupItem" in props && props.groupItem) {
        return <GroupFilterItem {...props} />;
    }

    if ("checkbox" in props && props.checkbox) {
        return <CheckboxFilterItem {...props} />;
    }

    if ("radioButton" in props && props.radioButton) {
        return <RadioFilterItem {...props} />;
    }

    if ("dropDown" in props && props.dropDown) {
        return <RadioFilterItem {...props} />;
    }

    if ("freeText" in props && props.freeText) {
        return <FreeTextFilterItem {...props} />;
    }

    if ("datepicker" in props && props.datepicker) {
        return <DateFilterItem {...props} />;
    }
    if ("rangepicker" in props && props.rangepicker) {
        return <RangeFilterItem {...props} />;
    }

    return <></>;
}

export default FilterItem;
