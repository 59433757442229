import { EntryListItemActionProps } from "@mediaspace/shared/ui/entry-list-item/EntryListItemAction";
import {
    ChannelSectionProps,
    EventPlatformEntry,
} from "@mediaspace/shared/types";
import React from "react";
import EntryListItem from "./../entry-list-item/EntryListItem";

export interface EventPlatformEntryItem extends EventPlatformEntry {
    actions?: EntryListItemActionProps[];
}

/**
 * lock the generic type to `EventPlatformEntryItem[]`
 */
export type EntriesListProps = ChannelSectionProps<EventPlatformEntryItem[]>;

/**
 * Entries list as used in personal profile and channel media sections.
 * Displays entry data, either scheduled or not, without scheduling info.
 */
function EntriesList({
    categoryId,
    content,
    additionalParams = {},
}: EntriesListProps) {
    const list = content.map((entry) => {
        const {
            name,
            description,
            duration,
            thumbnailUrl,
            id,
            customData = {},
            actions,
        } = entry;
        return (
            <EntryListItem
                key={`EntriesList${id}`}
                name={name}
                description={description}
                duration={duration}
                image={thumbnailUrl}
                entryId={id}
                categoryId={categoryId}
                customData={customData}
                showSpeakers={
                    additionalParams["showSpeakersForContentTabEntries"]
                        ? additionalParams["showSpeakersForContentTabEntries"]
                        : false
                }
                actions={actions}
            />
        );
    });
    return <>{list}</>;
}

export default EntriesList;
