import React from "react";
import styled from "@emotion/styled";
import {Typography, TypographyComponent} from "@kaltura/ds-react-components";
import {generalHtmlStyles} from "./../GeneralHtmlStyles";

/**
 * message wrapper, used for centering content on screen
 */
export const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

/**
 * inner message wrapper, used for centered content
 */
export const InnerContainer = styled("div", {
    shouldForwardProp: prop => (prop !== 'contentWidth' && prop !== 'centerContent')
})(({theme, contentWidth = 475, centerContent = false} : { theme?: any, contentWidth?: number, centerContent?: boolean }) => ({
    maxWidth: contentWidth,
    marginTop: theme.spacing(7.5),
    marginBottom: theme.spacing(7.5),
    padding: theme.spacing(6),
    ...centerContent && {
        textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding: theme.spacing(2),
    },
}));

/**
 * message title
 */
export const Title = styled(TypographyComponent)(({theme, component} : { theme?: any, component?: React.ElementType }) => ({
    display: "block",
    textAlign: "center",
}));

/**
 * message text
 */
export const BodyText = styled(Typography)(({theme} : { theme?: any }) => ({
    /* Tone (Light Theme)/Tone 2 */
    color: theme.kaltura.palette.tone2,
    ...generalHtmlStyles(theme),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: "block",
}));

