import ReactDOM from "react-dom";
import React, {ComponentType, Suspense} from "react";
import {Config, ConfigProvider} from "@mediaspace/shared/data-kms-config";
import { ThemeProvider } from "@mediaspace/shared/styled";
import { CheckInProps } from "@mediaspace/features/event-check-in";
const CheckIn = React.lazy(() => import("@mediaspace/features/event-check-in"));

/**
 * header is rendered independently in multiple pages, so it deserves its own "render" method
 * @param id
 * @param props
 */
const render = (
    id: string,
    props: CheckInProps & { context: Config }
) => {
    const { theming } = props.context;
    ReactDOM.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming.theme} mode={theming.mode}>
                    <CheckIn {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(id)
    );
};

export {
    render,
};