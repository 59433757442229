import styled from "@emotion/styled";
import { KmsTypeFilterItemGroup, FilterValues, KmsTypeFilterItemBase } from "@mediaspace/shared/types";
import { FilterItem } from "../filter-items/FilterItem";
import { CheckboxFilterItem } from "./CheckboxFilterItem";
import { RadioFilterItem } from "./RadioFilterItem";
import FormControl from "@mui/material/FormControl";

export interface GroupFilterItemProps extends KmsTypeFilterItemGroup {
	onChange: (
		param: string,
		value: string,
		checked: boolean,
		equalsAll: boolean
	) => void;
	values?: FilterValues;
}

const StyledGroupFilterItem = styled(FormControl)(({ theme }) => ({
	marginBottom: theme.spacing(2),
}));

/**
 * group filter item - a radio/checkbox item holding other items as a group
 *
 * the value of such item is always an empty string "", to differentiate it from the
 * no selection option.
 */
export function GroupFilterItem(props: GroupFilterItemProps) {
	const { checkbox, radioButton, items, values, onChange, ...itemProps } =
		props;

	const getItemValue = (item: KmsTypeFilterItemBase) => {
		if (!values || !values[item.param]) {
			return undefined;
		}
		if (Array.isArray(values[item.param])) {
			return values[item.param][0];
		}
		return values[item.param] as string;
	}

	return (
		<StyledGroupFilterItem>
			{checkbox && (
				<CheckboxFilterItem
					{...itemProps}
					value=""
					onChange={onChange}
				></CheckboxFilterItem>
			)}

			{radioButton && (
				<RadioFilterItem
					{...itemProps}
					value=""
					onChange={onChange}
				></RadioFilterItem>
			)}

			{itemProps.checked &&
				!itemProps.disabled &&
				items.map((item, index) => (
					<FilterItem
						key={item.param + index}
						{...item}
						value={getItemValue(item)}
						onChange={onChange}
					/>
				))}
		</StyledGroupFilterItem>
	);
}

export default GroupFilterItem;
