export const isMobile = () => window.innerWidth < 767;
export const isMobileDevice = /iPad|iPhone|iPod|tablet|Android|Mobile/.test(
    window.navigator.userAgent
);

export const isElementInViewport = (
    el: Element,
    scrollParent: Element
): boolean => {
    const { left, width } = el.getBoundingClientRect();
    const { left: parentLeft, width: parentWidth } =
        scrollParent.getBoundingClientRect();
    if (left + width < parentLeft) {
        return false;
    }
    return left <= parentLeft + parentWidth;
};

export const getTransitionEventName = (element: HTMLElement) => {
    let transition = "transitionend";

    if ("ontransitionend" in window) {
        // Firefox
        transition = "transitionend";
    }
    if ("onwebkittransitionend" in window) {
        // Chrome/Saf (+ Mobile Saf)/Android
        transition = "webkitTransitionEnd";
    }
    if ("onotransitionend" in element || navigator.appName == "Opera") {
        // Opera
        // As of Opera 10.61, there is no "onotransitionend" property added to DOM elements,
        // so it will always use the navigator.appName fallback
        transition = "oTransitionEnd";
    }
    return transition;
};

export const toggleFocusability = (
    ref: any,
    state: boolean,
    prefix?: string
) => {
    if (!ref) {
        return;
    }
    let selectors =
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';
    if (prefix) {
        selectors = selectors
            .split(",")
            .map((selector) => `${prefix} ${selector}`)
            .join(",");
    }
    const elements = ref.querySelectorAll(selectors);
    Array.from(elements).forEach((element: HTMLElement) =>
        element.setAttribute("tabindex", state ? "0" : "-1")
    );
};
