import ReactDOM from "react-dom";
import { Suspense, lazy } from "react";
import { ThemeProvider } from "@kaltura/ds-react-theme";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { InviteUsersProps } from "@mediaspace-react-components/features/invite-users";
import { Config, ConfigProvider } from "@mediaspace/shared/data-kms-config";

const InviteUsers = lazy(
    () => import("@mediaspace-react-components/features/invite-users")
);

const render = (
    id: string,
    props: InviteUsersProps & { context: Config }
) => {
    const { theming } = props.context;
    ReactDOM.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming.theme} mode={theming.mode}>
                    <InviteUsers {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(id)
    );
};

export { render, InviteUsers };
