import styled from "@emotion/styled";
import { FilterValues, FilterProps, KmsTypeFilterBase, KmsTypeFilterItemBase } from "@mediaspace/shared/types";
import { Link } from "@kaltura/ds-react-components";
import { translate, extractDefaultFilterValues } from "@mediaspace/shared/utils";
import { FilterWrapper } from "./FilterWrapper";
import { Filter } from "./filter/Filter";
import { getDisabledFilters } from "./dependencyUtils"
import { useButtonAnalytics } from "@mediaspace/hooks";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import { useMemo } from "react";
import { isEqual } from "lodash";

export interface FilterBarProps {
	filters: FilterProps<KmsTypeFilterBase<KmsTypeFilterItemBase>>[];
	values: FilterValues;
	onChange: (values: FilterValues) => void;
}

const StyledButton = styled(Link)(
	({ theme, component }: {theme?: any, component: "button"}) => ({
		marginLeft: theme.spacing(0),
		marginBottom: theme.spacing(2),
		textDecoration: "none"
	})
);


/**
 * vertical filter bar - holds all the filters, and manage their state
 */
export function FilterBar(props: FilterBarProps) {
	const sendButtonAnalytics = useButtonAnalytics();
	const { filters: initialFilters = [], values = {}, onChange } = props;
	const filters = getDisabledFilters(values, initialFilters);

	/**
	 * handle a single filter value change
	 *
	 * we expect the filterValues to be relevant for a specific filter params
	 * (filter main param, its group items params, free text items params)
	 */
	const handleChange = (filterValues: FilterValues) => {

		// new values for filter
		let currentFilterValues = {...values, ...filterValues};

		// update filter dependency by new values
		const disabledFilters = getDisabledFilters(currentFilterValues, initialFilters);

		// turn off values of disabled filters
		// (group item values are reset by the filters)
		disabledFilters.forEach(filter => {
			if (filter.disabled){
				currentFilterValues = {...currentFilterValues, [filter.param]: null}
			}
		});

		onChange(currentFilterValues);
	};

	/**
	 * default filters values
	 */
	const defaultValues = useMemo(() => {
		return extractDefaultFilterValues(initialFilters);
	}, [initialFilters])

	/**
	 * handle clear all filter values
	 */
	const handleClearAll = () => {
		sendButtonAnalytics("Filters - Clear all filters", ButtonClickAnalyticsType.FILTER);
		// reset to default filters values
		onChange(defaultValues);
	}


	return (
		<>
			<StyledButton onClick={handleClearAll} component="button"
						  disabled={isEqual(defaultValues, values)}
			>{translate('Clear all filters')}</StyledButton>
			{filters.filter(filter => !filter.hidden).map((filter, index) => ( 
				<FilterWrapper title={filter.title} param={filter.param} disabled={filter.disabled} key={index + filter.param}>
					<Filter {...filter} values={values ?? {}} onChange={handleChange} />
				</FilterWrapper>
			))}
		</>
	);
}

export default FilterBar;
