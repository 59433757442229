import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import {Theme} from "@mui/material";

// This component intended usage is to wrap top level components so they will have some margin between them and the footer.
export const FeatureContainer = styled(Box)(({ theme }: { theme?: Theme }) => ({
        marginBottom: theme.spacing(7.5),
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            marginBottom: theme.spacing(5)
        }
    })
);
