import ReactDOM from "react-dom";
import React, { Suspense } from "react";
import { ThemeProvider } from "@mediaspace/shared/styled";
import { Config, ConfigProvider } from "@mediaspace/shared/data-kms-config";
import { AuthBrokerLogoutPageProps } from "@mediaspace-react-components/features/auth-broker-logout";

const AuthBrokerLogoutPage = React.lazy(
    () => import("@mediaspace-react-components/features/auth-broker-logout")
);

const render = (
    containerId: string,
    props: AuthBrokerLogoutPageProps & { context: Config }
) => {
    const { theming } = props.context;
    ReactDOM.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming.theme} mode={theming.mode}>
                    <AuthBrokerLogoutPage {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(containerId)
    );
};

export { render };
