import styled from "@emotion/styled";
import { DatePicker, Typography } from "@kaltura/ds-react-components";
import FormControl from "@mui/material/FormControl";
import { Theme } from "@mui/material";
import { KmsTypeFilterItemDate } from "@mediaspace/shared/types";
import { useCallback, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";

export interface DateFilterItemProps extends KmsTypeFilterItemDate {
    onChange: (param: string, value: string, checked: boolean, equalsAll: boolean) => void;
}

const StyledDateFilterItem = styled(FormControl)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    maxWidth: 150,
}));

const StyledFieldLabel = styled(Typography)(
    ({ component, theme }: { component: "div"; theme?: Theme }) => ({
        color: theme.kaltura.palette.tone3,
        marginBottom: theme.spacing(1),
    })
);

/**
 * date filter item - a single date-field in a filter
 */
export function DateFilterItem({param, label, value, defaultValue, disabled, onChange, dateFormat}: DateFilterItemProps) {
    const sendButtonAnalytics = useButtonAnalytics();

    const convertValueToDayjs = useCallback((value:string) => {
        // outside the component we use unix timestamp (ms) as a string.
        // DatePicker component takes a dayjs object.
        return dayjs(parseInt(value));
    }, []);

    // current date: if value is given use that. otherwise, if default value is given, use that.
    const [currentDate, setCurrentDate] = useState<Dayjs | null>(() => (value ? convertValueToDayjs(value) : (defaultValue ? convertValueToDayjs(defaultValue) : null)))

    const handleChange =  (date: Dayjs, keyboardInputValue?: string) => {
        const eventName = `Filters - ${param}${date ? "" : " - Clear"}`
        sendButtonAnalytics(eventName, ButtonClickAnalyticsType.FILTER);
        if (date) {
            onChange(param, date.valueOf().toString(), true, false);
            return;
        }
        // clear:
        onChange(param, "", false, false);
    };

    useEffect(() => {
        if (!value) {
            return;
        }
        setCurrentDate(convertValueToDayjs(value));
    }, [value, convertValueToDayjs]);

    return (
        <StyledDateFilterItem>
            <StyledFieldLabel component={"div"} variant={"formLabel"}>
                {label}
            </StyledFieldLabel>
            <DatePicker onChange={handleChange} value={currentDate} disabled={disabled} inputFormat={dateFormat} />
        </StyledDateFilterItem>
    );
}

export default DateFilterItem;
