import React from "react";
import {
    BaseFormLabel as SharedFormLabel,
    baseFormLabelClasses,
    BaseFormLabelProps as SharedFormLabelProps
} from "./../../base-form-label/BaseFormLabel";
import styled from "@emotion/styled";


export interface FormLabelProps extends SharedFormLabelProps {
    fontWeightBold?: boolean;
}

export const StyledFormLabel = styled(SharedFormLabel, {
    shouldForwardProp: prop => prop !== 'fontWeightBold'
})<{ fontWeightBold?: boolean } >(({theme, fontWeightBold}: {theme?: any, fontWeightBold?: boolean}) => ({
    [`& .${baseFormLabelClasses.label}`]: {
        display: "block",
        paddingBottom: theme.spacing(1),
        color: theme.kaltura.palette.tone1,
        ...(fontWeightBold && {
            ...theme.kaltura.typography.formLabel,
        })
    }
}));


/**
 * Form Input Label
 */
export const FormLabel = (
    props: FormLabelProps) => {
    return (
        <StyledFormLabel {...props} variant={"body2"} />
    )
}
