import { FilterItem } from "../filter-items/FilterItem";
import {
	KmsTypeFilterItemBase,
	KmsTypeFilterCheckbox,
	KmsTypeFilterItemGroup, FilterProps
} from "@mediaspace/shared/types";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";

export type CheckboxFilterProps = FilterProps<KmsTypeFilterCheckbox>;

/**
 * checkbox filter - holds several checkbox items, manages their value and presentation
 *
 * we never allow checkbox items to have specific params.
 */
export function CheckboxFilter(props: CheckboxFilterProps) {
	const { title, param, values = {}, defaultValue, items, onChange } = props;

	// this filter values
	const filterValues = values[param] as string[] ?? [];

	// 'all' value
	const allValue = items.filter((item) => item["equalsAll"] ?? false)[0]
		?.value;
	// is the filter current value the 'all' value
	const equalsAllOn = filterValues[0] === allValue;

   // filter group items
    const groupItems = items.filter((item) => "items" in item);
    // filter group items' params (params of the group items, no empties and flattened)
    const itemParams = groupItems
        .map((item: KmsTypeFilterItemGroup) =>
            item.items.map((item) => item.param)
        )
        .flat()
        .filter((param) => param);


	const handleChange = (
		param: string,
		value: string,
		checked: boolean,
		equalsAll: boolean,
		hasItems: boolean
	) => {
		/**
		 * calculate the filter values, taking the 'all' value into account.
		 */
		const calculateValues = (values: string[], checked: boolean, equalsAll: boolean, newVal: string) => {
			// checked value is the 'all' value
			if (checked && equalsAll && !equalsAllOn) {
				// save selected 'all' value
				return [newVal];
			}

			// current filter value is the 'all' value
			if (checked && !equalsAll && equalsAllOn) {
				// replace current 'all' value with selected value
				return [newVal];
			}

			let updatedValues = [];

			if (checked) {
				// see if the given value is a fixed one or a custom value
				const itm = items.find(item => item.value === newVal);
				// if fixed, add value to current values
				if (itm) {
					updatedValues = [...values, newVal];
				}
				// if custom, replace a previous custom.
				else {
					// for each value, see if it matches an item
					// find the index of a custom item
					const ind = values.findIndex(val => !items.find(item => item.value === val));
					if (ind > -1) {
						// replace existing item
						values[ind] = newVal;
						updatedValues = values;
					}
					else {
						// add new value
						updatedValues = [...values, newVal];
					}
				}

				// if all checkboxes are now checked and there is an "all" checkbox
				if (allValue && updatedValues.length === items.length - 1) {
					// save 'all' value
					return [allValue];
				}

				return updatedValues;

			}

			if (!checked) {
				const newValues = values.filter(
					(currentValue) => currentValue !== newVal
				);

				// no checkboxes selected
				if (newValues.length === 0) {
					if (defaultValue) {
						// save default value
						return [defaultValue];
					} else if (allValue) {
						// save 'all' value
						return [allValue];
					}
				}

				return newValues;
			}
		};

		if(itemParams.includes(param)){
			// this is a group item
			const newFilterValues = {[param]: [value]};
			onChange(newFilterValues);
		}
		else {
			// regular checkbox item
			const newValues = calculateValues(filterValues, checked, equalsAll, value);
			const newFilterValues = {[param]: newValues};

			// checkbox contains group items
			if (hasItems && !checked) {
				// reset this filters group items values
                itemParams.forEach((param) => {
                    newFilterValues[param] = [];
                });
			}
			onChange(newFilterValues);
		}
	};

	const isChecked = (item: KmsTypeFilterItemBase) => {
		// we have values
		if (filterValues.length > 0) {
			if ("rangepicker" in item && item["rangepicker"]) {
				// see if there is a rangepicker value on the list
				return filterValues.find(value => (value.indexOf("-") > -1)) !== undefined;
			}
			// simple items, see if the value is in the list.
			return filterValues.includes(item.value);
		}

		// no values - default value
		if (defaultValue) {
			return item.value === defaultValue;
		}

		// no values - no default - allValue
		if (allValue) {
			return item.value === allValue;
		}

		return false;
	};

	return (
		<FormControl className="kms-ds-checkbox-filter">
			<FormGroup>
				{items.map((item, ind) => (
					<FilterItem
						key={ind}
						{...item}
						param={param}
						disabled={item.disabled}
						checked={isChecked(item)}
						values={values}
						onChange={(...params) => handleChange(...params, ("items" in item))}
					/>
				))}
			</FormGroup>
		</FormControl>
	);
}

export default CheckboxFilter;
