import React, { RefObject } from "react";
import styled from "@emotion/styled";
import {
	Button,
	IconButton,
	ButtonProps,
	buttonClasses,
} from "@kaltura/ds-react-components";
import { useMediaQuery } from "@mediaspace/shared/utils";
import { useTheme } from "@mediaspace/shared/styled";

interface AddNewMenuButtonProps extends ButtonProps {
	href?: string;
	component?: "a";
}

// we use this for the added `component` prop. ts wont allow it otherwise.
const StyledIconButton = styled(IconButton)(
	({ theme, component }: { theme?: any; component?: string }) => ({})
);

const StyledButton = styled(Button)(
	({ theme, component }: { theme?: any; component?: string }) => ({
		minWidth: "fit-content",
	})
);

/**
 * `Add New` menu header button. or possibly a link, for logged-out users.
 */
export const AddNewMenuButton = React.forwardRef(
	(props: AddNewMenuButtonProps, ref?: any) => {
		const { startIcon, ...rest } = props;
		const theme = useTheme();
		const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

		return (
			<>
				{smallScreen && (
					<StyledIconButton {...rest} variant={"pill"} ref={ref}>
						{startIcon}
					</StyledIconButton>
				)}
				{!smallScreen && (
					<StyledButton {...rest} kuid={"create-menu-add-new-link"} startIcon={startIcon} ref={ref} />
				)}
			</>
		);
	}
);
export default AddNewMenuButton;
