import styled from "@emotion/styled";
import { Box } from "@mediaspace/shared/styled";
import { Theme } from "@emotion/react";

export const Statistics = styled(Box)({
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
});

export const Separator = styled("div")(({ theme }: { theme?: Theme }) => ({
    borderRadius: "50%",
    backgroundColor: theme?.kaltura.palette.tone1,
    width: 5,
    height: 5,
    margin: theme?.spacing(0, 1)
}));
