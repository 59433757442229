import {TruncatedLine} from "@mediaspace/shared/ui";
import styled from "@emotion/styled";
import {Box} from "@mediaspace/shared/styled";
import {Button, Typography} from "@kaltura/ds-react-components";
import React, {MouseEvent} from "react";
import {Theme} from "@emotion/react";
import {ButtonClickAnalyticsType} from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import BadgeIcon from "../../common/BadgeIcon";
import {BadgeProps} from "../../common/types";
import {useBadgesModal} from "../../modal/useBadgesModal";
import {ArrowRight24Icon} from "@kaltura/ds-react-icons";
import {useButtonAnalytics} from "@mediaspace/hooks";
import ReactHtmlParser from "@mediaspace/shared/ui/html-react-parser";
import {stripTags} from "@mediaspace/shared/utils";

const StyledBadgeToEarn = styled("a")(({theme}: { theme: Theme}) => ({
    display: "flex",
    gap: theme.spacing(2),
    cursor: "pointer",
    width: "100%",
    justifyContent: "space-between"
}));

const StyledContent = styled(Box)(() => ({
    width: 144,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexGrow: 1
}));

// override bootstrap.min.js
const StyledTitleTypography = styled(Typography)(({theme}: { theme: Theme}) => ({
    color: theme.kaltura.palette.tone1
}));

// override bootstrap.min.js
const StyledDescriptionTypography = styled(Typography)(({theme}: { theme: Theme}) => ({
    color: theme.kaltura.palette.tone2
}));

const StyledIconContainer = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}));

const StyledButton = styled(Button)(({theme}: { theme: Theme}) => ({
    minWidth: 32,
    maxWidth: 32
}));

const staticClassBase = "kms-ds-header-badges-menu-badges-to-earn-item";

/**
 * header user badges menu badge-to-earn item component
 */
const BadgeToEarn = (props: BadgeProps) => {

    const {name, iconUrl, description} = props;

    const [toggleModal, Modal] = useBadgesModal({title: name, badgeIconUrl: iconUrl, description: description});

    const sendButtonAnalytics = useButtonAnalytics();

    const openBadgeModal = (evt: MouseEvent<HTMLAnchorElement>) => {
        evt.preventDefault();
        sendButtonAnalytics("Show Badge I can earn info - Header menu", ButtonClickAnalyticsType.LAUNCH)
        toggleModal();
    }

    return (
        <>
            <StyledBadgeToEarn
                className={`${staticClassBase}`}
                onClick={openBadgeModal}
            >
                <BadgeIcon
                    iconUrl={iconUrl}
                    showOpacity={true}
                    isSmall={true}
                />
                <StyledContent>
                    <StyledTitleTypography variant={"body1Highlight"} className={`${staticClassBase}-title`}>
                        <TruncatedLine>
                            {name}
                        </TruncatedLine>
                    </StyledTitleTypography>
                    <StyledDescriptionTypography variant={"body2"} className={`${staticClassBase}-description`}>
                        <TruncatedLine>
                            {ReactHtmlParser(stripTags(description, ['a', 'i', 'b', 'u']))}
                        </TruncatedLine>
                    </StyledDescriptionTypography>
                </StyledContent>
                <StyledIconContainer className={`${staticClassBase}-arrow`}>
                    <StyledButton variant={"borderless"}>
                        <ArrowRight24Icon />
                    </StyledButton>
                </StyledIconContainer>
            </StyledBadgeToEarn>

            {Modal}
        </>
    );
}

export default BadgeToEarn;
