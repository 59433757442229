import { useEffect } from "react";
import {ServerTimeHelper} from "@mediaspace/shared/utils";

/**
 * get the kaltura server time, and set it on the ServerTimeHelper.
 */
export function useKalturaServerTime(serviceUrl?: string) {
    useEffect(() => {
        // no service url => use local time
        if (!serviceUrl) {
            ServerTimeHelper.setTimeForDiff(Date.now());
            return () => {};
        }

        // get server time
        ServerTimeHelper.getServerTime(serviceUrl)
            .then((value) => {
                ServerTimeHelper.setTimeForDiff(value);
            })
            .catch((err) => {
                console.info(">> failed getting system time, using local");
                ServerTimeHelper.setTimeForDiff(Date.now());
            });

        return () => {};
    }, [serviceUrl]);

    return ServerTimeHelper.getUpdatedTime();
}
