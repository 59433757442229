import styled from "@emotion/styled";
import { FilterValues, KmsTypeFilterItemRange } from "@mediaspace/shared/types";
import { formControlLabelClasses } from "@mui/material";
import { CheckboxFormControl, Slider, Typography } from "@kaltura/ds-react-components";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { translate } from "@mediaspace/shared/utils";
import throttle from "lodash/throttle";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import { useButtonAnalytics } from "@mediaspace/hooks";

dayjs.extend(duration);

export interface RangeFilterItemProps extends KmsTypeFilterItemRange {
    onChange: (param: string, value: string, checked: boolean, equalsAll: boolean) => void;
    values?: FilterValues;

    /**
     * @deprecated
     * the `value` prop (inherited from `KmsTypeFilterItemBase`) is ignored on this component,
     * actual value is inferred by the relevant param in `values`.
     */
    value?: string;
}

const StyledRangeFilterItem = styled("div")(({ theme }) => ({
    marginTop: 0,
    marginBottom: theme.spacing(2),
    [`& .${formControlLabelClasses.label} p`]: { fontSize: 14 },
}));

const StyledSliderWrap = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 1.5),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(2, 0),
    color: theme.kaltura.palette.tone3,
}));

/**
 * range selector filter item
 */
export function RangeFilterItem({
    label,
    param,
    values,
    checked = false,
    onChange,
    disabled = false,
    minValue,
    maxValue,
    rangePickerType = "duration",
    step,
    labelSingular,
    labelPlural,
    unit,

}: RangeFilterItemProps) {
    const sendButtonAnalytics = useButtonAnalytics();
    // format the given value as a range of numbers, to be used by the slider:
    const [localValue, setLocalValue] = useState(() => {
        // get a unified form
        let paramValues = [];
        if (values && values[param]) {
            if (Array.isArray(values[param])) {
                paramValues = values[param] as string[];
            }
            else {
                paramValues = [values[param]];
            }
        }
        // if there is a value and it's a range, use that. otherwise, use whole range.
        const rangeValue = paramValues.find(value => (value.indexOf("-") > -1));
        return rangeValue ? rangeValue.split("-").map(s => parseInt(s)) : [minValue, maxValue]
    })

    const handleChange = useCallback((param: string, value: string, checked: boolean, equalsAll: boolean) => {
        const eventName = `Filters - ${param} - ${value}`
        sendButtonAnalytics(eventName, ButtonClickAnalyticsType.FILTER);
        onChange(param, value, checked, equalsAll);
    }, [onChange, sendButtonAnalytics])

    const throttled = useMemo(() => {
        return throttle(handleChange, 200, {trailing: true});
    }, [handleChange])

    const handleCheckboxClicked = (event: ChangeEvent<HTMLInputElement>) => {
        throttled.cancel();
        onChange(param, localValue.join("-"), event.target.checked, false);
    };

    const handleSliderChanged = (newValue: number[]) => {
        const v = newValue.join("-");
        if (v !== localValue.join("-")) {
            throttled(param, v, true, false);
        }
    };

    /**
     * value format for duration selection
     * @param value
     */
    const formatDurationLabel = (value: number) => {
        if (value >= 3600) {
            const d = dayjs.duration(value, "seconds").format("H:mm");
            return translate("%1 hours", [d]);
        }
        const d = dayjs.duration(value, "seconds").format("mm");
        return translate("%1 minutes", [d]);
    }

    /**
     * value format for generic selection
     * @param value
     */
    const formatGenericLabel = (value: number) => {
        value /= unit;
        return Math.trunc(value) === 1
            ? `${value} ${labelSingular}`
            : `${value} ${labelPlural}`;
    }

    /**
     * generate label according to current value and datepicker type
     * @param val min-max values
     */
    const getLabel = (val: number[]) => {
        const formatter = rangePickerType === "duration" ? formatDurationLabel : formatGenericLabel;
        return `${formatter(val[0])} - ${formatter(val[1])}`
    }

    /**
     * (required for accessibility)
     * provides a user-friendly name for the thumb labels of the slider
     * @param index thumb index (0 - lower / 1 - higher)
     */
    const getAriaLabel = (index: number) => {
        if (index === 0) {
            return translate("%1 range low end", [label]);
        }
        return translate("%1 range high end", [label]);
    }

    /**
     * (required for accessibility)
     * provides a user-friendly name for the current value of the slider
     * @param value
     * @param index
     */
    const getAriaValueText = (value: number, index: number) => {
        if (rangePickerType !== "duration") {
            return formatGenericLabel(value);
        }
        return formatDurationLabel(value);
    }


    return (
        <StyledRangeFilterItem>
            <CheckboxFormControl
                label={label}
                disabled={disabled}
                checked={checked}
                onChange={handleCheckboxClicked}
            />
            {checked && (
                <>
                    <StyledTypography variant={"body1"}>
                        {getLabel(localValue)}
                    </StyledTypography>
                    <StyledSliderWrap>
                        <Slider
                            min={minValue}
                            max={maxValue}
                            value={localValue}
                            onChange={(event: Event, newValue: number | number[]) => {
                                if (Array.isArray(newValue)) {
                                    setLocalValue(newValue);
                                    handleSliderChanged(newValue);
                                }
                            }}
                            disabled={disabled}
                            marks={step !== undefined}
                            step={step}
                            getAriaLabel={getAriaLabel}
                            getAriaValueText={getAriaValueText}
                        />
                    </StyledSliderWrap>
                </>
            )}
        </StyledRangeFilterItem>
    );
}

export default RangeFilterItem;
