/**
 * object containing general HTML styles according to the Design-System.
 * should be used to apply DS-styles to user-generated texts that have HTML content,
 * like descriptions, etc.
 * Usage example:
 * <pre>
 * const StyledUserGeneratedHtmlText = styled("div")(({ theme }: { theme?: any }) => ({
 *     ...generalHtmlStyles(theme),
 * }));
 *
 * ....
 *
 * <StyledUserGeneratedHtmlText>{ReactHtmlParser(userInput)}</StyledUserGeneratedHtmlText>
 * </pre>
 * @param theme
 */
export const generalHtmlStyles = (theme) => ({
    ["& h1"]: theme.typography.h1,
    ["& h2"]: theme.typography.h2,
    ["& h3"]: theme.typography.h3,
    ["& h4"]: theme.typography.h4,
    ["& h5"]: theme.typography.h5,
    ["& h6"]: theme.typography.h6,
    ["& a"]: {
        /** these values are copied from the ui-kit's Link component. */
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.primary.main,
        textDecorationColor: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.primary.dark,
        },
        "&:active": {
            color: theme.palette.primary.light,
        },
        "&:visited": {
            color: theme.palette.primary.main,
        },
    },
    ["& img"]: {
        // Large images shouldn't blow up the container
        maxWidth: "100%",
        // Make sure that the image maintains aspect ratio when there are "width" and "height" attributes on the <img> tag,
        // and the max-width rule above changes the width
        height: "auto",
    },
});
