import ReactDOM from "react-dom";
import React, { Suspense } from "react";
import { ThemeProvider } from "@mediaspace/shared/styled";
import { Config, ConfigProvider } from "@mediaspace/shared/data-kms-config";
import { ThemeEditorContainerProps } from "@mediaspace/features/studio/ThemeEditorContainer";

const ThemeEditor = React.lazy(() => import("@mediaspace/features/studio/ThemeEditor"));


export const render = (
    id: string,
    props: ThemeEditorContainerProps & { context: Config }
) => {
    const { theming } = props.context;

    ReactDOM.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming.theme} mode={theming.mode}>
                    <ThemeEditor {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(id)
    );
};
