import { FilterProps, KmsTypeFilterDate } from "@mediaspace/shared/types";
import FormGroup from "@mui/material/FormGroup";
import { DateFilterItem } from "./../filter-items/DateFilterItem";

export type DateFilterProps = FilterProps<KmsTypeFilterDate>;


/**
 * Date filter - holds several date filter items
 */
export function DateFilter({ param, values = {}, items, onChange }: DateFilterProps) {

    const handleChange = (param: string, value: string) => {
        // replace value with current values
        onChange({[param]: value});
    };

    return (
        <FormGroup className="kms-ds-date-filter">
            {items.map((item) => (
                <DateFilterItem
                    key={item.param}
                    {...item}
                    param={item.param ?? param}
                    disabled={item.disabled}
                    value={
                        values[item.param] ? values[item.param] as string : undefined
                    }
                    onChange={handleChange}
                />
            ))}
        </FormGroup>
    );
}

export default DateFilter;
