import styled from "@emotion/styled";
import { CheckboxFormControl } from "@kaltura/ds-react-components";
import { formControlLabelClasses } from "@mui/material";
import { KmsTypeFilterItemCheckbox } from "@mediaspace/shared/types";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import { ChangeEvent } from "react";

export interface CheckboxFilterItemProps extends KmsTypeFilterItemCheckbox {
	onChange: (param: string, value: string, checked: boolean, equalsAll: boolean) => void;
}

const StyledCheckboxFilterItem = styled("div")(({ theme }) => ({
	marginTop: 0,
	marginBottom: theme.spacing(2),
	[`& .${formControlLabelClasses.label} p`]: { fontSize: 14 },
}));

/**
 * checkbox filter item - a single (controlled)checkbox in a filter
 */
export function CheckboxFilterItem(props: CheckboxFilterItemProps) {
	const sendButtonAnalytics = useButtonAnalytics();
	const {
		label,
		param,
		value,
		checked = false,
		equalsAll = false,
		onChange,
		disabled = false,
	} = props;

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		if(equalsAll && !event.target.checked) {
			// equalsAll cant be unchecked manually
			return;
		}
		const eventName = `Filters - ${param} - ${value} - ${event.target.checked ? "Checked" : "Unchecked"} `
		sendButtonAnalytics(eventName, ButtonClickAnalyticsType.FILTER);
		onChange(param, value, event.target.checked, equalsAll);
	};

	return (
		<StyledCheckboxFilterItem className="kms-ds-checkbox-filter-item">
			<CheckboxFormControl
				label={label}
				value={value}
				disabled={disabled}
				checked={checked}
				onChange={handleChange}
			/>
		</StyledCheckboxFilterItem>
	);
}

export default CheckboxFilterItem;
