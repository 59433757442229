import {
    ModalActions,
    ModalTitle,
    ModalContent,
    Button,
    Typography,
    BaseInput
} from "@kaltura/ds-react-components";
import {baseUrl, postKmsData, translate} from "@mediaspace/shared/utils";
import {StyledModal, StyledListContainer, StyledSearch24Icon} from "./StyledComponents";
import React, {useState, useEffect} from "react";
import ListItem from "./list-item/ListItem";
import CreatePlaylist from "./create-playlist/CreatePlaylist";
import {uniqueId} from "lodash";
import {filterPlaylists, onInputKeyDown} from "../helpers";
import {kmsConnect, WrappedProps as KmsConnectProps} from "@mediaspace/shared/kms-connect";
import { useToastsContext } from "@mediaspace/shared/ui";
import { ActionsMenuItemProps } from "../../../ActionsMenu";

export interface AddToPlaylistProps extends ActionsMenuItemProps {
    entryId: string;
    categoryId?: string;
    allowCreate: boolean;
    playlists: PlaylistType[];
    extraPlaylists: PlaylistType[];
    onCloseMenu?: () => void;
    originalPlaylists?: PlaylistType[];
    additionalPlaylists?: PlaylistType[];
}

export interface AddToPlaylistModalProps extends AddToPlaylistProps, KmsConnectProps {
    isOpen: boolean;
}

export type PlaylistType = {
    name: string,
    id: string,
    isContainingEntry: boolean // does the playlist contain the entry
}

type ParamsType = {
    entry: string[];
    selectedPlaylist: string[];
    extraPlaylists: string[];
    playlistsToCreate: string[];
    bulk: boolean;
    categoryId?: string;
}

export type ChangesType = {
    [key: string]: boolean
}

/**
 * add to playlist modal
 */
const AddToPlaylistModal = (props: AddToPlaylistModalProps) => {

    const {isOpen, entryId, categoryId, allowCreate, playlists, extraPlaylists, sendToKms, onCloseMenu, onItemStateChange} = props;
    // creating a deep copy of playlists & extraPlaylists using JSON.parse(JSON.stringify(<originalObject>))
    const [originalPlaylists, setOriginalPlaylists] = useState<PlaylistType[]>(props.originalPlaylists ?? JSON.parse(JSON.stringify(playlists)));
    const [additionalPlaylists, setAdditionalPlaylists] = useState<PlaylistType[]>(props.additionalPlaylists ?? JSON.parse(JSON.stringify(extraPlaylists)));
    const [newPlaylists, setNewPlaylists] = useState<PlaylistType[]>([]);
    const [playlistsToShow, setPlaylistsToShow] = useState<PlaylistType[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [processing, setProcessing] = useState<boolean>(false);
    const [changes, setChanges] = useState<ChangesType>({});

    const { showToast } = useToastsContext();

    const handleCancel = () => {
        onCloseMenu?.();
    };

    const handleSubmit = async () => {
        setProcessing(true);
        const url = `${baseUrl}/playlistlogic/index/add-playlist-ds`;
        const params: ParamsType = {
            entry: [entryId],
            selectedPlaylist: originalPlaylists.filter(item => item.isContainingEntry).map(item => item.id),
            extraPlaylists: additionalPlaylists.filter(item => item.isContainingEntry).map(item => item.id),
            playlistsToCreate: newPlaylists.filter(item => item.isContainingEntry).map(item => item.name),
            bulk: false
        };
        if (categoryId) {
            params['categoryId'] = categoryId;
        }

        try {
            const {success, originalPlaylists, messages, errors} = await postKmsData(url, params);
            if (success) {
                onItemStateChange?.("originalPlaylists", originalPlaylists);
                onItemStateChange?.("additionalPlaylists", additionalPlaylists);
                messages.forEach((message: string) => showToast({severity: "success", message}));
            } else {
                errors.forEach((error: string) => showToast({severity: "error", message: error}));
            }
        } catch (e: unknown) {
            console.log(e);
        }

        setProcessing(false);
        onCloseMenu?.();
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handleNewPlaylist = (name: string) => {
        const newPlaylist: PlaylistType = {
            name,
            id: uniqueId(),
            isContainingEntry: true
        };
        const changesCopy = {...changes};
        changesCopy[newPlaylist.id] = true;
        setChanges(changesCopy);
        setNewPlaylists([...newPlaylists, newPlaylist]);
        setPlaylistsToShow([...playlistsToShow, newPlaylist]);
    };

    const handleCheckboxChange = (id: string, isChecked: boolean) => {
        const changesCopy = {...changes};
        if (id in changesCopy) {
            delete changesCopy[id];
        } else {
            changesCopy[id] = isChecked
        }

        // search for the updated checkbox in playlists list
        const originalIndex = originalPlaylists.findIndex(item => item.id === id);
        if (originalIndex >= 0) {
            originalPlaylists[originalIndex].isContainingEntry = isChecked;
            setOriginalPlaylists(originalPlaylists);
        } else {
            // search for the updated checkbox in extra playlists list, only if wasn't found in playlists list
            const additionalIndex = additionalPlaylists.findIndex(item => item.id === id);
            if (additionalIndex >= 0) {
                additionalPlaylists[additionalIndex].isContainingEntry = isChecked;
                setAdditionalPlaylists(additionalPlaylists);
            } else {
                // search for the updated checkbox in new playlists list, only if wasn't found in playlists & extra playlists lists
                const newIndex = newPlaylists.findIndex(item => item.id === id);
                if (newIndex >= 0) {
                    newPlaylists[newIndex].isContainingEntry = isChecked;
                    setNewPlaylists(newPlaylists);
                }
            }
        }

        setChanges(changesCopy);
        setPlaylistsToShow(filterPlaylists(originalPlaylists, additionalPlaylists, newPlaylists, searchTerm));
    };

    const isDirty = Object.keys(changes).length > 0;

    useEffect(() => {
        setPlaylistsToShow([...JSON.parse(JSON.stringify(playlists)), ...JSON.parse(JSON.stringify(extraPlaylists))]);
    }, []);

    useEffect(() => {
        setPlaylistsToShow(filterPlaylists(originalPlaylists, additionalPlaylists, newPlaylists, searchTerm));
    }, [searchTerm]);

    return (
        <StyledModal open={isOpen}>
            <ModalTitle>
                <div>
                    <Typography variant={"h3"}>
                        {translate("Add to playlist")}
                    </Typography>
                </div>
            </ModalTitle>
            <ModalContent>
                <BaseInput
                    placeholder={translate("Search playlist")}
                    startAdornment={<StyledSearch24Icon />}
                    fullWidth={true}
                    onChange={handleSearch}
                    onKeyDown={onInputKeyDown}
                />

                {playlistsToShow.length > 0 && (
                    <StyledListContainer>
                        {playlistsToShow.map((playlist: PlaylistType) => (
                                <ListItem
                                    key={playlist.id}
                                    name={playlist.name}
                                    id={playlist.id}
                                    isAdded={playlist.isContainingEntry}
                                    onChange={handleCheckboxChange}
                                />

                        ))}
                    </StyledListContainer>
                )}

                {allowCreate && (
                   <CreatePlaylist
                        onNew={handleNewPlaylist}
                   />
                )}

            </ModalContent>
            <ModalActions>
                <Button
                    variant={"borderless"}
                    onClick={handleCancel}
                >
                    {translate("Cancel")}
                </Button>
                <Button
                    onClick={handleSubmit}
                    disabled={!isDirty}
                    loading={processing}
                >
                    {translate("Add")}
                </Button>
            </ModalActions>
        </StyledModal>
    );
}

export default kmsConnect<AddToPlaylistModalProps>(AddToPlaylistModal);
