import {
    createContext,
    FC,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Box, styled } from "@mui/material";
import { sidebarMobileBreakpoint, useIsMobileSidebarLayout, useSidebarLayoutContext, sidebarContentsWidth, sidebarButtonsSize } from "@kaltura/ds-react-layouts";
import { CncProps } from "./CncProps";
import { useProcessedCncConfig } from "./useProcessedCncConfig";
import { KMS_GLOBAL } from "@mediaspace/shared/utils";

// region Styled components
const StyledSidebarTopButtons = styled(Box)(({ theme }) => ({
    flex: 1,
    minWidth: 0,
    [theme.breakpoints.up(sidebarMobileBreakpoint)]: {
        width: "100%",
    },
}));
const StyledSidebarContents = styled(Box)({
    position: "absolute",
    inset: 0,
});
const StyledReactionsContainer = styled(Box)(({ theme }) => ({
    width: 48,
    height: 48,
    margin: theme.spacing(2),
}));

const StyledLiveReactionsContainer = styled(Box)({
    position: "relative"
});

const StyledFloatingReactions = styled(Box)(({ theme }) => ({
    position: "absolute",
    right: 0,
    bottom: 0,
    [theme.breakpoints.up(sidebarMobileBreakpoint)]: {
        display: "none",
    },
}));
// endregion

// region Context
export interface EmbeddedCncWidgetContextData {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cncWidget?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setCncWidget: (cncWidget?: any) => void;
    showReactions: boolean,
    setShowReactions: (showReactions: boolean) => void;
}

/**
 * Context for sharing the C&C widget object between components that are spread out across the layout regions.
 * The provider for this context should be rendered in the top level of the application (see DsLayout).
 */
const EmbeddedCncWidgetContext = createContext<EmbeddedCncWidgetContextData>({
    setCncWidget: () => {},
    showReactions: false,
    setShowReactions: () => {},
});

export const EmbeddedCncWidgetContextProvider: FC = ({ children }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [cncWidget, setCncWidget] = useState<any>();
    const [showReactions, setShowReactions] = useState(false);
    const contextData = useMemo<EmbeddedCncWidgetContextData>(
        () => ({ cncWidget, setCncWidget, showReactions, setShowReactions }),
        [cncWidget, setCncWidget, showReactions, setShowReactions]
    );
    return (
        <EmbeddedCncWidgetContext.Provider value={contextData}>
            {children}
        </EmbeddedCncWidgetContext.Provider>
    );
};

export const useEmbeddedCncWidgetContext = () => useContext(EmbeddedCncWidgetContext);
// endregion

/*
 * Parts of embedded C&C widget, component per DS layout region.
 * The mapping could be found in the ChatAndCollaboration module.
 */

/**
 * Initialize the widget object
 */
export const EmbeddedCncContainer = (props: CncProps) => {
    const { isOpened, setIsOpened } = useSidebarLayoutContext();
    const isMobile = useIsMobileSidebarLayout();
    const { setCncWidget } = useEmbeddedCncWidgetContext();

    // Add config properties that depend on time/state
    const fulfilledConfig = useProcessedCncConfig(props);

    const [container, setContainer] = useState<HTMLDivElement | null>(null);

    const cncWidget = useMemo(() => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const widget = (window as any).KalturaEventsPlatform.collaboration.widgets.embedded.new();
            KMS_GLOBAL.cncWidget = widget;
            return widget;
        } catch (err) {
            console.error("Failed to load chat widget: " + err);
            return undefined;
        }
    }, []);

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (!cncWidget || !container) {
            return;
        }

        const onLoadFinished = () => setIsLoaded(true);
        const onOpen = () => setIsOpened(true);
        const onClose = () => setIsOpened(false);

        cncWidget.addOnLoadFinished(onLoadFinished);
        cncWidget.addOnOpen(onOpen);
        cncWidget.addOnClose(onClose);

        if (props.openByDefault && !isOpened && !isMobile) {
            onOpen();
        }

        cncWidget.renderCncContainer(container, fulfilledConfig);

        return () => {
            setIsLoaded(false);
            setIsOpened(false);

            cncWidget.removeOnLoadFinished(onLoadFinished);
            cncWidget.removeOnOpen(onOpen);
            cncWidget.removeOnClose(onClose);

            cncWidget.unmountCncContainer();
        };
    }, [cncWidget, container, fulfilledConfig, setIsLoaded, setIsOpened]);

    useEffect(
        () => setCncWidget(isLoaded ? cncWidget : undefined),
        [cncWidget, setCncWidget, isLoaded]
    );

    return <div ref={setContainer} />;
};

export const EmbeddedCncSidebarTopButtons = () => {
    const { cncWidget } = useEmbeddedCncWidgetContext();

    const [container, setContainer] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!cncWidget || !container) {
            return;
        }

        cncWidget.renderSidebarButtons(container);

        return () => {
            cncWidget.unmountSidebarButtons();
        };
    }, [cncWidget, container]);

    return <StyledSidebarTopButtons ref={setContainer} />;
};

export const EmbeddedCncSidebarBottomButtons = () => {
    const { cncWidget } = useEmbeddedCncWidgetContext();

    const [container, setContainer] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!cncWidget || !container) {
            return;
        }

        cncWidget.renderSidebarSettings(container);

        return () => {
            cncWidget.unmountSidebarSettings();
        };
    }, [cncWidget, container]);

    return (
        <>
            <EmbeddedCncReactionsDesktop />
            <div ref={setContainer} />
        </>
    );
};

export const EmbeddedCncSidebarContents = () => {
    const { cncWidget } = useEmbeddedCncWidgetContext();

    const [container, setContainer] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!cncWidget || !container) {
            return;
        }

        cncWidget.renderCnc(container);

        return () => {
            cncWidget.unmountCnc();
        };
    }, [cncWidget, container]);

    return <StyledSidebarContents ref={setContainer} />;
};

const CncReactionsBase = ({isMobile = false, isOpened = false}) => {
    const { cncWidget, showReactions } = useEmbeddedCncWidgetContext();

    const [container, setContainer] = useState<HTMLDivElement | null>(null);
    const [liveReactionsContainer, setLiveReactionsContainer] = useState<HTMLDivElement | null>(null);

    const [isLoaded, setIsLoaded] = useState(false);
    const [isLiveReactionsAvailable, setIsLiveReactionsAvailable] = useState(false);

    useEffect(() => {
        if (!cncWidget) {
            return;
        }

        const onLoadFinished = () => setIsLoaded(true);

        cncWidget.addOnReactionsLoadFinished(onLoadFinished);

        return () => {
            cncWidget.removeOnReactionsLoadFinished(onLoadFinished);
        };
    }, [cncWidget, container, setIsLoaded]);

    useEffect(() => {
        if (!cncWidget || !isLoaded) {
            return;
        }

        if (showReactions) {
            cncWidget.startReactions();
        } else {
            cncWidget.endReactions();
        }
    }, [cncWidget, isLoaded, showReactions]);

    useEffect(() => {
        if (!cncWidget || !container) {
            return;
        }

        cncWidget.renderReactions(container);
        if (cncWidget.renderLiveReactions) {
            setIsLiveReactionsAvailable(true);
            cncWidget.renderLiveReactions(liveReactionsContainer);
        }

        /*
         * Note: we don't call unmountReactions() on dispose because the component is rendered in different places
         * according to the window width, and we have no control of the callback call order (we can't ensure that
         * unmountReactions of the previous location will be called before renderReactions of the new location)
         * same applies to unmountLiveReactions()
         */
    }, [cncWidget, container, liveReactionsContainer]);

    return <>
        {isLiveReactionsAvailable &&
            <StyledLiveReactionsContainer
                ref={setLiveReactionsContainer}
                style={{
                    top: isMobile ? 48 : 0,
                    right: isMobile ? 20 : isOpened ? (sidebarContentsWidth + sidebarButtonsSize + 20) : sidebarButtonsSize + 20
                }}
            />
        }
        <StyledReactionsContainer ref={setContainer} />
    </>
};

/**
 * Reactions widget for the desktop layout (rendered as a part of the sidebar)
 */
export const EmbeddedCncReactionsDesktop = () => {
    const isMobile = useIsMobileSidebarLayout();
    const {isOpened} = useSidebarLayoutContext();
    if (isMobile) {
        return null;
    }

    return <CncReactionsBase isOpened={isOpened} />;
};

/**
 * Reactions widget for the mobile layout (rendered floating in the main region)
 */
export const EmbeddedCncReactionsMobile = () => {
    const isMobile = useIsMobileSidebarLayout();
    const {isOpened} = useSidebarLayoutContext();
    if (!isMobile || isOpened) {
        return null;
    }

    return (
        <StyledFloatingReactions>
            <CncReactionsBase isMobile={true} />
        </StyledFloatingReactions>
    );
};
