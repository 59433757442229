import ReactDOM from "react-dom";
import React, { Suspense } from "react";
import { FooterProps } from "@mediaspace/footer";
import { ThemeProvider } from "@mediaspace/shared/styled";
import { Config, ConfigProvider } from "@mediaspace/shared/data-kms-config";

const Footer = React.lazy(() => import("@mediaspace/footer"));

export const Component = Footer;

export const render = (
    id: string,
    props: FooterProps & { context: Config }
) => {
    const { theming } = props.context;
    ReactDOM.render(
        <Suspense fallback={<div/>}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming.theme} mode={theming.mode} scoped={true}>
                    <Footer {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(id)
    );
};
