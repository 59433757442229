import { Config, defaultContext } from "@mediaspace/shared/data-kms-config";
import { HeaderMenu, HeaderMenuProps } from "@mediaspace/header-menu";
import { Footer } from "@mediaspace/footer";

interface MockDsLayoutConfigOptions {
    headerColor?: "dark" | "light" | "transparent";
    headerProps?: HeaderMenuProps;
}

/**
 * DS layout config for storybook.
 *
 * Usage:
 * ```
 * <DsLayout
 *     context={getMockDsLayoutConfig()}
 *     component={() => <>...</>}
 * />
 * ```
 */
export const getMockDsLayoutConfig = ({ headerColor, headerProps }: MockDsLayoutConfigOptions): Config => ({
    ...defaultContext,
    dsLayout: {
        components: {
            sidebarTopButtons: [{
                name: "Button",
                component: () => <div>Button</div>,
            }],
            header: (headerColor || headerProps) ? [{
                name: "Header",
                component: HeaderMenu,
                props: headerProps ?? {
                    headerColor,
                    logo: {
                        hasLogo: false,
                    },
                    showNav: true,
                    scrollThreshold: 60,
                },
            }] : [],
            footer: [{
                name: "Footer",
                component: Footer,
                props: {
                    backgroundColor: "#2E2E2E",
                    backgroundUrl: "",
                    textAndLinks: [
                        {
                            sectionText: "Corp Site",
                            sectionType: "URL",
                            sectionLink: "https://corp.kaltura.com"
                        },
                        {
                            sectionText: "Corp Site but in a very very very long text soooooooooooo long its not reasonable, really not",
                            sectionType: "URL",
                            sectionLink: "https://corp.kaltura.com"
                        },
                        {
                            sectionText: "Pricing",
                            sectionType: "URL",
                            sectionLink: "https://corp.kaltura.com/pricing"
                        },
                        {
                            sectionText: "Learning",
                            sectionType: "URL",
                            sectionLink: "https://learning.kaltura.com"
                        },
                        {
                            sectionText: "Script",
                            sectionType: "Script",
                            sectionLink: "alert('hi!!!');"
                        },
                        {
                            sectionText: "Empty Script",
                            sectionType: "Script",
                            sectionLink: ""
                        }
                    ],
                    linkSeparator: "\u2022",
                    bottomText: "Kaltura, Inc. or its affiliates, All Rights Reserved"
                }
            }],
        },
        headerColor,
    },
});
