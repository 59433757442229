/**
 *  Search History Filter ObjectTypeIn values
 */

export enum SearchHistoryObjectType {
    entry = "entry",
    category = "category",
    user = "kuser",
    all = "",
}
