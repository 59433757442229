import styled from "@emotion/styled";
import {Box} from "@mediaspace/shared/styled";

export const StyledCreatePlaylistContainer = styled(Box)(({ theme }: { theme?: any }) => ({
    marginTop: theme.spacing(2)
}));

export const StyledForm = styled(Box)(({ theme }: { theme?: any }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1)
}));
