import styled from "@emotion/styled";
import {Modal, ModalContent, modalClasses, Typography, Button, modalContentClasses} from "@kaltura/ds-react-components";
import {Theme} from "@emotion/react";
import {alpha} from "@mediaspace/shared/styled";

export const StyledModal = styled(Modal)(({theme} : { theme: Theme }) => ({
    [`& .${modalClasses.paper}`]: {
        width: 584,
        overflowY: "visible",
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            borderRadius: `${theme.kaltura.shape.roundness3}px !important`
        }
    }
}))

export const StyledModalContent = styled(ModalContent)(({theme} : { theme: Theme }) => ({
    backgroundColor: theme?.kaltura.palette.surfaces.background,
    backgroundImage: `linear-gradient(180deg, ${alpha(theme.palette.common.white, 0)} 3.58%, ${alpha(theme.palette.primary.light, 0.7)} 123.38%)`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    borderRadius: "inherit",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [`&.${modalContentClasses.root}`]: {
        paddingBottom: theme.spacing(5)
    }
}));

export const StyledButton = styled(Button)(({ theme }: { theme: Theme }) => ({
    position: "absolute",
    top: -85,
    right: -32,
    width: 40,
    height: 40,
    "& svg": {
        color: theme.palette.common.white
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        top: -60
    }
}));

export const StyledBackground = styled("img")(({ theme }: { theme: Theme }) => ({
    position: "absolute",
    width: "95%",
    top: theme.spacing(2)
}));

export const StyledImage = styled("img")(({ theme }: { theme: Theme }) => ({
    width: 150,
    height: 150,
    borderRadius: "50%",
    position: "absolute",
    top: -85,
    left: "calc(50% - 75px)",
    boxShadow: theme.kaltura.elevations.medium,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        width: 120,
        height: 120,
        top: -60,
        left: "calc(50% - 60px)"
    }
}));

export const StyledTitle = styled(Typography)(({ theme }: { theme: Theme }) => ({
    color: theme.kaltura.palette.tone1,
    marginTop: theme.spacing(8),
    textAlign: "center",
    fontSize: theme.typography.pxToRem(32),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        marginTop: theme.spacing(5),
        fontSize: theme.typography.pxToRem(24),
    }
}));

export const StyledTypography = styled(Typography)(({ theme }: { theme: Theme }) => ({
    textTransform: "uppercase",
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.kaltura.typography.fontWeightBold,
    letterSpacing: 1
}));

export const StyledDescription = styled(Typography)(({ theme }: { theme: Theme }) => ({
    marginTop: theme.spacing(2)
}));
