import { FilterProps, KmsTypeFilterFreeText } from "@mediaspace/shared/types";
import { FreeTextFilterItem } from "../filter-items/FreeTextFilterItem";
import FormGroup from "@mui/material/FormGroup";

export type FreeTextFilterProps = FilterProps<KmsTypeFilterFreeText>;

/**
 * Free text filter - holds several free text filter items
 */
export function FreeTextFilter(props: FreeTextFilterProps) {
    const { title, param, values = {}, defaultValue, items, onChange } = props;

    const handleChange = (param: string, value: string) => {
        // replace value with current values
        onChange({[param]: value});
    };

    return (
        <FormGroup className="kms-ds-freetext-filter">
            {items.map((item) => (
                <FreeTextFilterItem
                    key={item.param}
                    {...item}
                    param={item.param ?? param}
                    disabled={item.disabled}
                    value={
                        values[item.param] ? values[item.param] as string : undefined
                    }
                    onChange={handleChange}
                />
            ))}
        </FormGroup>
    );
}

export default FreeTextFilter;
