import ReactDOM from "react-dom";
import React, { Suspense } from "react";
import { ThemeProvider } from "@mediaspace/shared/styled";
import { Config, ConfigProvider } from "@mediaspace/shared/data-kms-config";
import { PlayerUpgradeToolProps } from "@mediaspace/player-upgrade-tool";

const PlayerUpgradeTool = React.lazy(() => import("@mediaspace/player-upgrade-tool"));



const render = (
    id: string,
    props: PlayerUpgradeToolProps & { context: Config }
) => {
    const { theming } = props.context;
    ReactDOM.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming.theme} mode={theming.mode}>
                    <PlayerUpgradeTool {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(id)
    );
};

export {
    render
};
