import { styled } from "@mui/material";
import { MenuItem, NestedMenuItem } from "@kaltura/ds-react-components";
import { LinkMenuItemProps } from "../link/LinkMenuItem";
import { ChevronRight16Icon } from "@kaltura/ds-react-icons";
import { ActionsMenuItemProps } from "../../ActionsMenu";
import {ButtonClickAnalyticsType} from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import {useButtonAnalytics} from "@mediaspace/hooks";

interface MenuItemProps extends LinkMenuItemProps {
    /**
     * formatted size string
     */
    size: string;
}

interface DownloadMediaItemProps extends ActionsMenuItemProps {
    label: string;
    items: MenuItemProps[];
}
// Help typescript understand the actual props of the component
const StyledMenuItem = styled(MenuItem)(
    (_: { component: "a"; href: string; target?: string }) => ({})
);

const StyledDuration = styled("span")(({ theme }) => ({
    color: theme.kaltura.palette.tone3,
    marginLeft: theme.spacing(1),
}));

/**
 * Download media menu item for media-page title area actions menu
 */
export const DownloadMediaItem = (props: DownloadMediaItemProps) => {
    const { label, items, autoFocus } = props;

    const sendButtonAnalytics = useButtonAnalytics();

    const onMenuButtonClick = (actionName: string, buttonType: ButtonClickAnalyticsType) => {
        sendButtonAnalytics(actionName, buttonType);
    }

    return (
        <NestedMenuItem
            label={label}
            icon={<ChevronRight16Icon />}
            autoFocus={autoFocus}
            onClick={() => onMenuButtonClick("Media Actions - Download menu", ButtonClickAnalyticsType.MENU)}
        >
            {items &&
                items.map((item: MenuItemProps, index: number) => {
                    const { href, label, size } = item;

                    return (
                        <StyledMenuItem
                            key={index}
                            href={href}
                            component="a"
                            onClick={() => onMenuButtonClick("Media Actions - Download item", ButtonClickAnalyticsType.DOWNLOAD)}
                        >
                            {label}
                            <StyledDuration>{size}</StyledDuration>
                        </StyledMenuItem>
                    );
                })}
        </NestedMenuItem>
    );
};
