import {
    FilterProps,
    FilterValues,
    KmsTypeFilterBase,
    KmsTypeFilterDependency,
    KmsTypeFilterItemBase
} from "@mediaspace/shared/types";

/**
 * filter/item dependency
 */
const isDisabled = (filterValues: FilterValues, {param = "", whiteList = undefined, blackList = undefined} : KmsTypeFilterDependency) => {
    // whitelist dependency
    if (whiteList && whiteList.length &&
        !whiteList.some(value => {
            if (Array.isArray(filterValues[param])) {
                // `filterValues[param]` is array:
                return !!filterValues[param]?.includes(value);
            }
            // `filterValues[param]` is a string, and it is the same as `value`
            else return filterValues[param] === value;

        }) ) {
        // not in whitelist
        return true;
    }

    // blacklist dependency
    if (
        blackList && blackList.length &&
        blackList.some((value) => {
            if (Array.isArray(filterValues[param])) {
                // `filterValues[param]` is array:
                return !!filterValues[param]?.includes(value);
            }
            // `filterValues[param]` is a string, and it is the same as `value`
            else return filterValues[param] === value;
        })
    ) {
        // in blacklist
        return true;
    }

    // just param, no whitelist, no blacklist
    if(param){
        return !filterValues[param];
    }

    return false;
}

/**
 * calculate filters and filter items dependency
 * @return FilterProps<KmsTypeFilterBase>[]
 */
export const getDisabledFilters = ((filterValues: FilterValues, filters: FilterProps<KmsTypeFilterBase<KmsTypeFilterItemBase>>[]) => {
    return filters.map(filter => {
        // filter dependency
        const disabled = filter.disabled || (filter.depends && isDisabled(filterValues, filter.depends));

        // filter item dependency
        const items = filter.items.map(item => {
            const disabled = item.disabled || (item.depends && isDisabled(filterValues, item.depends));

            return {...item, disabled: disabled};
        });

        return {...filter, disabled: disabled, items: items};
    })
});
