import { PropsWithChildren } from "react";
import {Drawer as MUIDrawer, useMediaQuery} from "@mui/material";
import styled from "@emotion/styled";
import {Theme, useTheme} from "@emotion/react";
import {Button} from "@kaltura/ds-react-components";
import {Close16Icon} from "@kaltura/ds-react-icons";

export interface DrawerProps {
    open: boolean;
    onClose: () => void;
    maxWidth: number;
}

const StyledContainer = styled("div", {
    shouldForwardProp(propName: PropertyKey) {
        return propName !== "maxWidth";
    },
})(({theme, maxWidth}: {theme?: Theme; maxWidth: number}) => ({
    position: "relative",
    width: "100vw",
    height: "100vh",
    overflowY: "auto",
    boxSizing: "border-box",
    padding: `${theme?.spacing(4)} ${theme?.spacing(2)}`,
    [theme?.breakpoints.up("sm") || ""]: {
        maxWidth,
        width: `calc(100vw - ${theme?.spacing(8)})`,
        padding: `${theme?.spacing(4)} ${theme?.spacing(5)}`,
    },
}));

const StyledCloseButton = styled(Button)(({theme}: {theme: Theme}) => ({
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: theme.kaltura.palette.translucent.dark,
    color: theme.kaltura.palette.translucent.light,
    [theme.breakpoints.up("sm")]: {
        top: theme.spacing(2),
        right: `calc(100% + ${theme.spacing(2)})`,
    },
}));

const StyledDrawer = styled(MUIDrawer)(({theme}: {theme: Theme}) => ({
    zIndex: theme.zIndex.modal
}));

/**.
 * a MUI Drawer component with a close button
 */
export function Drawer({
                           open,
                           onClose,
                           maxWidth,
                           children,
                       }: PropsWithChildren<DrawerProps>) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const closeButton = (
        <StyledCloseButton variant={"borderless"} onClick={onClose}>
            <Close16Icon />
        </StyledCloseButton>
    );

    return (
        <StyledDrawer
            open={open}
            onClose={onClose}
            anchor={"right"}
            PaperProps={{
                sx: (theme: Theme) => ({
                    // Make overflow visible to see the close button outside
                    overflow: "visible",
                    background: theme.kaltura.palette.surfaces.background,
                }),
            }}
        >
            {/* On desktop, the close button should be outside the internal modal component*/}
            {!isMobile && closeButton}

            <StyledContainer maxWidth={maxWidth}>
                {/* On mobile, the close button should be under the vertical scroller of the modal content */}
                {isMobile && closeButton}

                {children}
            </StyledContainer>
        </StyledDrawer>
    );
}

