import {Config, useConfig} from "@mediaspace/shared/data-kms-config";
import {sendButtonClickAnalytics} from "@mediaspace/shared/utils";

/**
 * Custom hook to send button analytics
 * @param string actionName
 * @param number buttonType
 * @param string entryId
 */
export const useButtonAnalytics = () => {

    const config: Config = useConfig();

    const sendButtonAnalytics = (actionName: string, buttonType: number, entryId?: string) => {
        const analyticsContext = config.analytics?.analyticsContext;
        const prefixedActionName = analyticsContext + actionName;
        sendButtonClickAnalytics(
            prefixedActionName,
            config.analytics?.pageType,
            buttonType,
            entryId ?? config.analytics?.entryId
        );
    }

    return sendButtonAnalytics;
}
