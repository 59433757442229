// libs/components/src/lib/components/Sidebar/ToggleMenuButton/ToggleMenuButton.tsx

import React from "react";
import styled from "@emotion/styled";
import { IconButton, IconButtonClasses } from "@kaltura/ds-react-components";
import { Clear24Icon, Menu24Icon } from "@kaltura/ds-react-icons";
import { translate } from "@mediaspace/shared/utils";

export type ToggleMenuButtonProps = {
    isSidebarOpen: boolean;
    handleClickOpenSidebar?: () => void;
    handleKeyDownMenuButton?: (
        e: React.KeyboardEvent<HTMLButtonElement>
    ) => void;
    tabIndex?: number;
    classes?: Partial<IconButtonClasses>;
};

const StyledButton = styled(IconButton)(({ theme }: { theme?: any }) => ({
    color: theme.palette.common.white,
    padding: theme.spacing(1.5, 1),
    [`&:hover, &:focus`]: {
        backgroundColor: "initial",
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        width: theme.spacing(4),
    }
}));

/*
 * Open/close the Sidebar on click, tab
 */
const ToggleMenuButton: React.FC<ToggleMenuButtonProps> = ({
    isSidebarOpen,
    handleClickOpenSidebar,
    handleKeyDownMenuButton,
    tabIndex,
    classes,
}) => {
    return (
        <StyledButton
            classes={classes}
            color="translucent"
            aria-label={
                isSidebarOpen
                    ? translate("Close side bar")
                    : translate("Open side bar")
            }
            onClick={handleClickOpenSidebar}
            onKeyDown={handleKeyDownMenuButton}
            aria-haspopup={true}
            aria-expanded={isSidebarOpen}
            tabIndex={tabIndex}
        >
            {!isSidebarOpen && (
                <Menu24Icon className={"kms-ds-sidebar-menu-icon-opened"} />
            )}
            {isSidebarOpen && (
                <Clear24Icon className={"kms-ds-sidebar-menu-icon-closed"} />
            )}
        </StyledButton>
    );
};

export default ToggleMenuButton;
