import styled from "@emotion/styled";
import { useState, useEffect } from "react";
import { MenuItem, Switch, InputLabel } from "@kaltura/ds-react-components";
import { kmsCookie, booleanProp } from "@mediaspace/shared/utils";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    // override eventsplatform css
    ".eventsplatform &": {
        color: "inherit",
        [`&:hover`]: {
            color: "inherit",
        },
    },
    // override bootstrap css
    "input[type='checkbox']": {
        margin: 0,
        cursor: "inherit",
    },
    "& > div" : {
        paddingTop: 5,
    },
}));

const StyledInputLabel = styled(InputLabel)(({ theme }: { theme?: any }) => ({
    display: "inline-block",
    lineHeight: "24px",
    marginBottom: 0,
    minWidth: 120,
}));

const StyledSwitch = styled(Switch)(({ theme }: { theme?: any }) => ({
    float: "right",
    // override bootstrap css
    "span" : {
        boxSizing: "inherit"
    }
}));

/**
 * High Contrast Menu Item for the user menu
 */
export const HighContrastMenuItem = (props: { title: string }) => {
    const { title } = props;
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        // initial state from cookie
        const checked = booleanProp(kmsCookie("kms-contrast") as string);
        setChecked(checked);
    }, []);

    const handleChecked = (checked: boolean) => {
        setChecked(checked);

        const date = new Date();
        // set cookie for 10 years
        date.setTime(date.getTime() + 10 * 365 * 24 * 60 * 60 * 1000);

        const options = {
            expires: date,
            path: "/",
        };

        // set cookie
        kmsCookie("kms-contrast", checked ? 'true' : null, options);

        // fire custom event - used by theme provider to set the high contrast prop
        const event = new CustomEvent("kms-contrast", {});
        document.dispatchEvent(event);
    };

    return (
        <StyledMenuItem>
            <StyledInputLabel>{title}</StyledInputLabel>
            <StyledSwitch
                checked={checked}
                onChange={(event, checked) => {
                    handleChecked(checked);
                }}
            />
        </StyledMenuItem>
    );
};
export default HighContrastMenuItem;
