import {formatters, translate} from "@mediaspace/shared/utils";
import styled from "@emotion/styled";
import { alpha, Box, composeClasses } from "@mediaspace/shared/styled";
import { SimpleThumbnailClasses, getSimpleThumbnailClass } from "./simpleThumbnailClasses";
import clsx from "clsx";

export interface ThumbnailProps {
    classes?: Partial<SimpleThumbnailClasses>;
    thumbnailUrl: string;
    name: string;
    duration?: number;
    isLive?: boolean;
}


const slots = {
    root: ["root"],
    image: ["image"],
    indicator: ["indicator"],
    live: ["live"],
};

const useUtilityClasses = (styleProps: ThumbnailProps) => {
    const { classes } = styleProps;

    return composeClasses(slots, getSimpleThumbnailClass, classes);
};

const ThumbnailContainer = styled(Box)(({ theme }: { theme?: any }) => ({
    minWidth: 144,
    minHeight: 84,
    width: "100%",
    height: "100%",
    borderRadius: theme.kaltura.shape.roundness1,
    overflow: "hidden",
    position: "relative"
}));

const StyledImage = styled("img")(({ theme }: { theme?: any }) => ({
    width: "100%",
    height: "100%",
    verticalAlign: "middle"
}));

const Live = styled("div")(({ theme }: { theme?: any }) => ({
    position: "absolute",
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    backgroundColor: theme.kaltura.palette.danger.main,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    padding: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.kaltura.typography.fontWeightBold,
    borderRadius: theme.kaltura.shape.roundness1
}));

const Duration = styled("div")(({ theme }: { theme?: any }) => ({
    position: "absolute",
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    backgroundColor: alpha(theme.palette.common.black, 0.6),
    color: theme.palette.common.white,
    padding: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.kaltura.typography.fontWeightBold,
    borderRadius: theme.kaltura.shape.roundness1
}));

/**
 * thumbnail with duration or live indication
 * used in media-page (related entries)
 * used in global-search (media-card)
 */
export const SimpleThumbnail = (props: ThumbnailProps) => {

    const {thumbnailUrl, name, duration, isLive = false} = props;
    const classes = useUtilityClasses( props);

    return (
        <ThumbnailContainer className={clsx(classes.root, isLive && classes.live)}>
            <StyledImage src={thumbnailUrl} alt={name} className={classes.image} />
            {isLive && (
                <Live className={classes.indicator}>
                    {translate("LIVE")}
                </Live>
            )}
            {!isLive && duration && (
                <Duration className={classes.indicator}>
                    {formatters.formatSecondsToTime(duration)}
                </Duration>
            )}
        </ThumbnailContainer>
    )
}

