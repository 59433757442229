import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { PropsWithChildren, useState, useEffect } from "react";
import { Typography } from "@kaltura/ds-react-components";
import Accordion, { accordionClasses } from "@mui/material/Accordion";
import AccordionSummary, {
	accordionSummaryClasses,
} from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ChevronDown16Icon } from "@kaltura/ds-react-icons";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";

export interface FilterWrapperProps {
	title: string;
	param: string;
	disabled?: boolean;
}

const StyledFilterWrapper = styled(Accordion)(({ theme }) => ({
	backgroundColor: "transparent",
	[`&.${accordionClasses.disabled}`]: {
		backgroundColor: "transparent",
	},
	"&:before": {
		backgroundColor: theme.kaltura.palette.tone6,
		marginLeft: 0,
		marginRight: 0,
	},
	[`&.${accordionClasses.expanded}`]: {
		"&:before": {
			opacity: 1,
		},
	},
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: theme.spacing(0, 0),
	[`& .${accordionSummaryClasses.content}`]: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	paddingTop: 0,
	paddingBottom: 0,
	paddingLeft: 0,
}));

/**
 * Wrapper for a single filter, with title and accordion
 */
export function FilterWrapper(props: PropsWithChildren<FilterWrapperProps>) {
	const sendButtonAnalytics = useButtonAnalytics();
	const { title, param, disabled, children } = props;
	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		setExpanded((expanded) => expanded && !disabled);
	}, [disabled]);

	const handleClick = () => {
		if (expanded) {
			sendButtonAnalytics(`Filters - Collapse filter - ${param}`, ButtonClickAnalyticsType.COLLAPSE);
		}
		else {
			sendButtonAnalytics(`Filters - Expand filter - ${param}`, ButtonClickAnalyticsType.EXPAND);
		}

		setExpanded((expanded) => !expanded);
	};

	return (
		<StyledFilterWrapper
			disableGutters
			elevation={0}
			disabled={disabled}
			expanded={expanded}
		>
			<StyledAccordionSummary
				onClick={handleClick}
				expandIcon={<ChevronDown16Icon />}
				aria-controls={`${param}-content`}
				id={`${param}-header`}
			>
				<Typography variant="body2Highlight">{title}</Typography>
			</StyledAccordionSummary>
			<StyledAccordionDetails>{children}</StyledAccordionDetails>
		</StyledFilterWrapper>
	);
}

export default FilterWrapper;
