import ReactDOM from "react-dom";
import React, { Suspense } from "react";
import { ThemeProvider } from "@mediaspace/shared/styled";
import { Config, ConfigProvider } from "@mediaspace/shared/data-kms-config";
import type { AuthBrokerProfilesPageProps } from "@mediaspace/features/auth-broker-profiles";

const AuthBrokerProfilesPage = React.lazy(() => import("@mediaspace/features/auth-broker-profiles"));

export const render = (id: string, props: AuthBrokerProfilesPageProps & { context: Config }) => {
    const { theming } = props.context;

    ReactDOM.render(
        <Suspense fallback={<div/>}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming.theme} mode={theming.mode}>
                    <AuthBrokerProfilesPage {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(id)
    );
};
