/**
 *  custom metadata field types
 */

export enum CustomMetadataFieldType {
    TEXT = "textType",
    LIST = "listType",
    DATE = "dateType",
    OBJECT = "objectType",
}
